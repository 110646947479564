import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import ContactModal from './ContactModal';

interface IHeaderProps {

}

const routes = [
  {
    path: '/conseil',
    title: 'CONSEIL/EXPERTISE'
  },
  {
    path: '/vendre',
    title: 'A VENDRE'
  },
  {
    path: '/services',
    title: 'Développement'
  },
  {
    path: '/portfolio',
    title: 'PORTFOLIO'
  },
  {
    path: '/about',
    title: 'équipe'
  },
  {
    path: '/blog',
    title: 'Blog'
  },
];

const Header = ({}: IHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const l = useLocation();

  const openMenu = () => {
    document.body.classList.add('menu-open')
  };

  const closeMenu = () => {
    document.body.classList.remove('menu-open')
  };

  return (
    <header className="header">
      <ContactModal isOpen={isOpen} setOpen={setIsOpen}/>
      <div className="container">
        <Link to={'/'} className="header-logo"><img src={require('../assets/images/logo.png')} alt="New Home (logo)" /></Link>
        <div className="mobile-menu">
          <span className="mobile-menu_close" onClick={closeMenu}></span>
          <Link onClick={closeMenu} to={'/'} className="header-logo"><img src={require('../assets/images/logo.png')} alt="New Home (logo)" /></Link>
          <nav className="header-nav">
            <ul className="header-menu">
              {
                routes.map((r, i) => {
                  const cn = l.pathname.startsWith(r.path) ? 'active' : '';

                  if(r.path==="/vendre"){
                    return (<li className={cn} ><span onClick={()=>{
                       closeMenu()
                       window.location.href="/vendre"
                    }} >{r.title}</span></li>)
                  }
                  return (
                    <li className={cn} ><Link onClick={closeMenu} to={r.path} >{r.title}</Link></li>
                  )
                })
              }
            </ul>
          </nav>
          <a onClick={() => {
            setIsOpen(true);
            (window as any).dataLayer.push({event: 'contactClick'});
            }
          } className="btn btn-blue btn-left"><span>Contact</span></a>
        </div>
        <a onClick={() => {
            setIsOpen(true);
            (window as any).dataLayer.push({event: 'contactClick'});
            }
        } className="mobile-contact" ><img src={require('../assets/images/icon-contact2.svg')} /></a>
        <span className="btn-menu" onClick={openMenu}>
                <i></i>
                <i></i>
                <i></i>
            </span>
      </div>
    </header>
  )
};

export default Header;
