import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useForm } from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';
import { Link } from 'react-router-dom';

interface IBlogArticle1Props {}

const BlogArticle8 = ({}: IBlogArticle1Props) => {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-bg">
      <NewsLetterModal
        isOpen={isOpen}
        onClose={handleClose}
        defaultIsSuccess={isSuccess}
      />
      <div className="page page-about">
        <Header />
        <main className="blog-main">
          <div className="container blog-big_top">
            <p className="back-link">
              <Link to={'/blog'}>
                <span>Retour au blog</span>
              </Link>
            </p>

            <article className="blog-big">
              <div className="blog-columns2 blog-big_first">
                <div className="blog-columns2_left">
                  <h1>
                    Interdiction des chauffages électriques, quels impacts pour
                    les propriétaires ?
                  </h1>
                  <p>&nbsp;</p>
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog8/blog8_big.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2">
                <div className="blog-columns2_left">
                  <div className="main">
                    Fin 2022, le canton de Vaud a annoncé la fin des chauffages
                    électriques d’ici dix ans. Les propriétaires devront se
                    conformer à cette exigence et assainir leur logement en
                    conséquence. Les coûts d’un tel changement ne sont pas
                    négligeables : dans certains cas, la vente de sa maison ou
                    de son appartement paraissent même plus avantageux que de
                    lourds travaux d’assainissement.
                  </div>
                  <p className="highlighted">
                    Une législation qui se renforce contre les systèmes de
                    chauffage électrique
                  </p>
                  <p>
                    La récente décision des autorités vaudoises d’interdire les
                    chauffages électriques à l’horizon 2033 s’inscrit dans un
                    objectif d’efficience énergétique. Cette préoccupation très
                    actuelle devrait conduire la Confédération à généraliser
                    cette pratique à moyen terme. Dans le canton de Vaud, on
                    estime entre 16'000 et 20'000 le nombre de logements
                    concernés par cette mesure, pour un impact énergétique
                    d’environ 10% de la consommation électrique vaudoise
                    annuelle. Trois millions de subventions sont prévus pour
                    accompagner les ménages qui n’arriveraient pas assumer les
                    coûts de cet assainissement énergétique dont l’échéance est
                    fixée au 1<sup>er</sup> janvier 2033.
                  </p>
                  <p className="highlighted">
                    Un changement de système de chauffage onéreux
                  </p>
                  La modification complète du système de chauffage d’un
                  appartement ou d’une maison est loin d’être anodine et dépend
                  fortement de l’installation existante. Une chaudière
                  électrique, qui distribue l’eau chaude dans les radiateurs,
                  peut être remplacée relativement aisément par un système de
                  pompe à chaleur. Le changement d’un système dit
                  « décentralisé » est beaucoup plus complexe et coûteux. Il
                  nécessite en effet la pose de tuyaux dans les murs ou les sols
                  et le raccordement à une chaudière nouvellement installée. Ces
                  changements en profondeurs sont souvent accompagnés de travaux
                  de maçonnerie, par exemple de la création de chapes et de
                  nouveaux revêtements. Des alternatives sont possibles,
                  notamment la pose de panneaux photovoltaïques, mais ces
                  solutions peuvent également se révéler coûteuses.
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog8/blog8_2.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2">
                <div className="blog-columns2_left">
                  <p className="highlighted">
                    Vendre sa propriété plutôt que l’assainir ?
                  </p>
                  <p>
                    Quand on prend en considération les coûts de
                    l’assainissement de son logement, couplés aux désagréments
                    engendrés par des travaux qui dureront de nombreux mois, on
                    arrive vite à se poser la question de la vente de son bien
                    immobilier. Il faut alors peser le pour et le contre. Dans
                    le cas où les inconvénients semblent dépasser les avantages,
                    vendre sa propriété devient une solution à envisager pour
                    éviter des dépenses considérables et des ennuis au quotidien
                    lors de la rénovation. Il sera alors intéressant d’acheter
                    un logement plus moderne avec un système de chauffage déjà
                    aux normes.
                  </p>
                  <p>
                    Depuis 2007, NewHome Services se positionne comme un
                    partenaire de confiance pour la vente de votre maison ou de
                    votre appartement. Son équipe possède une expertise de
                    pointe dans le domaine pour les cantons de Vaud et de
                    Genève.
                    <br />
                    Si votre bien immobilier utilise encore un chauffage
                    électrique et que vous souhaitez obtenir des conseils
                    personnalisés pour le vendre, nous vous invitons à nous
                    contacter dès à présent. Nous vous proposerons une approche
                    sur-mesure qui correspond à vos besoins.
                  </p>
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog8/blog8_3.png')}
                    />
                  </figure>
                </div>
              </div>
            </article>
          </div>
        </main>
        <section className="section-subscribe">
          <div className="container">
            <article className="section-subscribe_left">
              <p className="title">NEWSLETTER</p>
              Recevez des conseils et informations sur l'actualité de
              l'immobilier avec notre newsletter.
            </article>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-subscribe_form">
              <Input
                conClassName={'input'}
                errors={errors}
                name={'email'}
                label={'Email*'}
                ref={register(validation.email)}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-gradient">
                Je m’inscris
              </button>
            </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogArticle8;
