import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useForm } from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';
import { Link } from 'react-router-dom';

interface IBlogArticle1Props {}

const BlogArticle6 = ({}: IBlogArticle1Props) => {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-bg">
      <NewsLetterModal
        isOpen={isOpen}
        onClose={handleClose}
        defaultIsSuccess={isSuccess}
      />
      <div className="page page-about">
        <Header />
        <main className="blog-main">
          <div className="container blog-big_top">
            <p className="back-link">
              <Link to={'/blog'}>
                <span>Retour au blog</span>
              </Link>
            </p>

            <article className="blog-big">
              <div className="blog-columns2 blog-big_first">
                <div className="blog-columns2_left">
                  <h1>Vers un immobilier plus écologique et durable</h1>
                  <p>&nbsp;</p>
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog6/blog6_big.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2">
                <div className="blog-columns2_left">
                  <div className="main">
                    L’immobilier a un impact considérable sur l’environnement.
                    On estime que le bâti produit actuellement 24% des émissions
                    carbone et est responsable de 40% des dépenses énergétiques
                    en Suisse. L’OFEN (Office fédéral de l'énergie) a fixé des
                    objectifs ambitieux pour le secteur à l’horizon 2050,
                    notamment la réduction de la consommation énergétique au m2
                    de 60% par rapport à 2010 et la suppression du mazout, du
                    gaz et de l’électricité directe comme sources de chauffage.
                    Leur réalisation passe par plusieurs mesures dont la
                    modernisation des nouvelles constructions.
                  </div>
                  <p className="highlighted">
                    Une construction plus écologique et responsable
                  </p>
                  <p>
                    On entend de plus en plus fréquemment parler d’«
                    écoconstruction » : il s’agit d’une approche qui intègre au
                    premier plan le développement durable lors de la
                    construction et l’exploitation des bâtiments.
                  </p>
                  Les normes énergétiques y jouent un rôle primordial : des
                  labels tels que Minergie, SNBS Bâtiment, DGNB, LEED, BREEAM,
                  ou encore la norme ISO SIA 112/1 sont devenus des références
                  et offrent la possibilité au propriétaire de bénéficier de
                  subventions étatiques, en plus d’être garants d’une
                  consommation énergétique réduite.
                  <p className="highlighted">
                    L’innovation au service de l’immobilier
                  </p>
                  Vient ensuite l’innovation dans le choix des matériaux et des
                  technologies de gestion du bâti. Dans le meilleur des cas,
                  elle peut permettre à un bâtiment de produire de l’énergie
                  positive, c’est-à-dire de produire plus qu’il ne consomme. Les
                  objets connectés et smart homes participent à cette dynamique
                  : en récupérant, agrégeant et analysant les données d’un
                  bâtiment, ils permettent d’optimiser ses différents aspects,
                  par exemple la température ou la lumière dans les pièces tout
                  en réduisant sa consommation d’énergie. Parmi les autres
                  technologies récentes, on peut mentionner les panneaux
                  photovoltaïques, de plus en plus courants, la régulation
                  thermique directement via des fenêtres qui font office de
                  capteurs solaires (Fluidglass) ou encore le plâtre
                  purificateur d’air.
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog6/blog6_2.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2">
                <div className="blog-columns2_left">
                  <p className="highlighted">
                    Les écoquartiers, l’aboutissement d’une stratégie de
                    développement durable
                  </p>
                  Au-delà de l’amélioration des bâtiments en tant que tels, les
                  écoquartiers constituent une forme de planification urbaine
                  révélatrice de ce changement de paradigme : en plus de faire
                  la part belle au bâti respectueux de l’environnement, ces
                  quartiers privilégient la réduction et le recyclage des
                  déchets, une meilleure gestion des déplacements, la réduction
                  de la consommation énergétique et la création de nombreux
                  espaces verts propices au partage. Ils constituent ainsi
                  l’aboutissement d’une stratégie de développement durable dans
                  l’immobilier et se dessinent comme le futur du tissu urbain
                  pour répondre aux enjeux climatiques.
                  <p className="highlighted">
                    NewHome Services s’engage pour l’écologie
                  </p>
                  <p>
                    Fondée en 2007, la société NewHome Services est spécialiste
                    du marché de l’immobilier résidentiel et des promotions dans
                    les cantons de Vaud et de Genève. Elle s’engage depuis de
                    nombreuses années pour la transition écologique de
                    l’immobilier et y participe activement à travers ses
                    projets.
                  </p>
                  Si vous souhaitez en savoir plus sur nos engagements pour le
                  développement durable et la manière dont nous pouvons vous
                  accompagner dans ce domaine, nous vous invitons à nous
                  contacter dès à présent.
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog6/blog6_3.png')}
                    />
                  </figure>
                </div>
              </div>
            </article>
          </div>
        </main>

        <section className="section-subscribe">
          <div className="container">
            <article className="section-subscribe_left">
              <p className="title">NEWSLETTER</p>
              Recevez des conseils et informations sur l'actualité de
              l'immobilier avec notre newsletter.
            </article>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-subscribe_form">
              <Input
                conClassName={'input'}
                errors={errors}
                name={'email'}
                label={'Email*'}
                ref={register(validation.email)}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-gradient">
                Je m’inscris
              </button>
            </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogArticle6;
