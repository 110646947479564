import {useEffect} from 'react';

interface IIncludeScriptProps {
  name: string;
}

const IncludeScript = ({name}:IIncludeScriptProps) => {
  useEffect(() => {
    const staticPath = process.env.PUBLIC_URL + '/js/';

    let contactSelect = document.createElement('script');
    contactSelect.src = staticPath + name + '.js';
    contactSelect.id = name;
    document.body.appendChild(contactSelect);

    return () => {
      contactSelect.remove();
    };
  }, []);

  return null;
};

export default IncludeScript;
