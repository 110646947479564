import React, {useState} from 'react';
import Header from '../components/Header';
import {Link} from 'react-router-dom';
import Footer from '../components/Footer';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';

interface IBlogArticle1Props {}

const BlogArticle5 = ({}: IBlogArticle1Props) => {
  const {handleSubmit, register, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-bg">
      <NewsLetterModal isOpen={isOpen} onClose={handleClose} defaultIsSuccess={isSuccess} />
      <div className="page page-about">
        <Header />
        <main className="blog-main">
          <div className="container blog-big_top">
              <p className="back-link"><Link to={'/blog'}><span>Retour au blog</span></Link></p>

              <article className="blog-big">
                  <div className="blog-columns2 blog-big_first">
                      <div className="blog-columns2_left">
                          <h1>L’IMPACT DE L’INFLATION SUR L’ACCÈS À LA PROPRIÉTÉ</h1>
                          <p>&nbsp;</p>
                      </div>
                      <div className="blog-columns2_right">
                    <figure className="pic"><img src={require("../assets/images/blog/blog5/blog5_2.png")}/></figure>
                      </div>
                  </div>
                  <div className="blog-columns2">
                      <div className="blog-columns2_left">
                          <h3>COMMENT L’INFLATION INFLUENCE-T’-ELLE L’ACCÈS À LA PROPIÉTÉ ?</h3>
                      </div>
                      <div className="blog-columns2_right">
                      </div>
                  </div>
                  <div className='blog-text blog-tablet blog-info'>
                      <p className="highlighted">Les répercussions sur I´accès à là propiété</p>
                      L’inflation est un phénomène qui s’est largement accentué ces derniers mois. La pandémie de Covid-19 et les récents événements en Europe de l’Est présagent un renforcement de cette tendance dans les prochaines années. L’inflation désigne une perte du pouvoir d’achat de la monnaie et se traduit par une augmentation globale et durable des prix. Elle a ainsi un impact considérable sur le portefeuille des particuliers et pourrait avoir, à terme, des conséquences sur les prix de l’immobilier jusqu’en Suisse. Analyse de la situation.
                      <p className="highlighted">L’impact de l’inflation sur le marché immobilier mondial</p>
                      Les économistes considèrent habituellement que le taux d’inflation doit se situer entre 1 et 2%.
                      Actuellement, ces indicateurs se situent plutôt autour de 8% aux ÉtatsUnis, de 6% en Europe et de 3% en Suisse. Cette situation extraordinaire encourage les banques centrales à augmenter les taux d’intérêt directeurs, une décision qui constitue un frein à l’accès à la propriété : elle réduit en effet le nombre d’individus qui peuvent prétendre à l’achat d’un bien immobilier. Ce phénomène se constate déjà en Europe et aux États-Unis et devrait continuer à se renforcer.
                  </div>
              </article>
          </div>
          <div className="container">
              <article className="blog-big">
                  <section className="blog-big_pic">
                  <figure className="pic-big"><img src={require("../assets/images/blog/blog5/blog5_3.png")}/></figure>
                      <div className="blog-text blog-tablet">
                          <p className="highlighted">Des conséquences à prévoir jusqu’en Suisse, malgré un marché immobilier solide</p>
                          Jusqu’à présent le marché immobilier en Suisse a été préservé. La viabilité des acheteurs est en effet calculée par les banques sur un taux d’intérêt hypothécaire théorique situé entre 4.5 et 5%, alors que ces taux se situent en réalité entre 1 et 2%. Si cette mesure donne une certaine résilience à l’immobilier helvétique, elle ne contrecarre pas complètement les effets de l’inflation. Une réduction du nombre d’emprunteurs à moyen ou long terme est tout à fait envisageable. Cette situation conduirait à une baisse de la demande et, par la même occasion, à un fléchissement des prix de l’immobilier, même en Suisse.
                          <p className="highlighted">2022, une année propice pour vendre son bien immobilier</p>
                          L’incertitude actuelle et l’inflation galopante laissent penser que les prix de l’immobilier ont atteint un pic ces dernières années. La conjoncture actuelle et les prévisions économiques présagent une baisse, relative et progressive, de la valeur des appartements et des maisons dans les années à venir. Les experts constatent déjà que les prix à la vente en Suisse romande ont tendance à stagner après une période de hausse importante et continue. 2022 semble par conséquent être le moment propice pour vendre sa propriété à des montants records pour l’immobilier.
                      </div>
                  </section>

                  <div className="blog-columns">
                      <div className="blog-columns_left">
                          <p className="highlighted">NEWHOME SERVICES, VOTRE PARTENAIRE IMMOBILIER PRIVILÉGIÉ</p>
                          Créée en 2007, la société NewHome Services est spécialiste du marché de l’immobilier résidentiel et des promotions dans les cantons de Vaud et de Genève. Elle saura vous accompagner dans l’estimation de votre terrain pour en obtenir un prix de vente équitable ou dans la mise en valeur de votre parcelle grâce à un développement personnalisé.<br/>
                          Contactez-nous dès à présent pour obtenir plus d’informations sur nos services et tirer parti de votre patrimoine immobilier !
                      </div>
                      <div className="blog-columns2_right">
                    <figure className="pic"><img src={require("../assets/images/blog/blog5/blog5_4.png")}/></figure>
                      </div>
                  </div>
              </article>
          </div>
        </main>
        <section className="section-subscribe">
          <div className="container">
              <article className="section-subscribe_left">
                  <p className="title">NEWSLETTER</p>
                  Recevez des conseils et informations sur l'actualité de l'immobilier avec notre newsletter.
              </article>
              <form onSubmit={handleSubmit(onSubmit)} className="section-subscribe_form">
              <Input conClassName={'input'} errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
                  <button disabled={isLoading} type="submit" className="btn btn-gradient">Je m’inscris</button>
              </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogArticle5;
