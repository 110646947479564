import React from 'react';
import {useEffect} from 'react';
import {Router} from 'react-router-dom';
import ProviderScrollToTop from './context/ScrollToTopContext';
import {isBrowser} from './utils/isBrowser';
import { createMemoryHistory, createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';
import routes from './routes';
import TagManager from 'react-gtm-module';
import config from './config';

let history: any = {};
history = !isBrowser ? createMemoryHistory() : createBrowserHistory();

if (config.enableGTM && isBrowser) {
  const GTMArgs = {
    gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
  };
  TagManager.initialize(GTMArgs);
}

function App() {
  useEffect(() => { if(!localStorage.getItem('user')){(window as any).dataLayer.push({event: 'newUser'})
    localStorage.setItem('user','true')}
  },[])

  const Content = () => {
    return (
      <div className="App">
        {renderRoutes(routes)}
      </div>
    );
  };

  return (
    <Router history={history}>
      <ProviderScrollToTop>
        <Content/>
      </ProviderScrollToTop>
    </Router>
  );
}

export default App;
