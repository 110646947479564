import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactModal from '../components/ContactModal';
import IncludeScript from '../components/IncludeScript';
import BrochureModal from '../components/BrochureModal';

const ArboreaPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <div className="page-bg">
      <div className="page page-project">
        <Header />
        <ContactModal isOpen={isOpen} setOpen={setIsOpen} />
        <IncludeScript name={'owlCarousel'}/>
        <main className="project-main">
          <div className="container">
            <h1>Arborea</h1>

            <article className="intro-text">
                <p className="bold">Bienvenue à Arborea : L'Équilibre Parfait entre Ville et Nature</p>
                Au cœur du Mont-sur-Lausanne, Arborea offre une rare combinaison de tranquillité villageoise et de proximité avec la dynamique Lausanne. À deux pas, écoles, commerces, et transports publics enrichissent votre quotidien.
                <p className="bold">Durabilité et Confort Innovant</p>
                Engagé dans l'innovation écologique, Arborea répond aux exigences Minergie grâce entre autres au chauffage par pompes à chaleur, fenêtres triple vitrage, panneaux solaires et ventilation contrôlée. Découvrez des maisons éco-responsables, où chaque détail favorise un style de vie respectueux de l'environnement.
                <p className="bold">Un Design Pensé pour le Bien-être</p>
                Nos villas représentent le summum de l'architecture moderne : trois chambres dont une suite parentale avec dressing, un bureau adapté au télétravail, et un grand espace de vie ouvert. L'harmonie entre esthétique et fonctionnalité crée un cadre de vie idéal.
                <p className="bold">Espaces Extérieurs Privatifs : Votre Refuge Personnel</p>
                Chaque villa s'ouvre sur une terrasse et un jardin privatif, vous offrant un havre de paix en plein air, parfait pour se relaxer ou partager des moments en famille.
            </article>

            <section className="project-gallery">
                <h3>Gallery</h3>
                <div id="sync1" className="owl-carousel owl-theme portfolio-slider-big">
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea1.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea2.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea3.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea4.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea5.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea6.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea7.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="portfolio-big"><img src={require('../assets/images/project/arborea/arborea8.jpg')} /></figure>
                  </div>
                </div>

                <div id="sync2" className="owl-carousel owl-theme portfolio-slider-thumb">
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea1.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea2.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea3.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea4.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea5.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea6.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea7.jpg')} /></figure>
                  </div>
                  <div className="item">
                    <figure className="carousel-pic"><img src={require('../assets/images/project/arborea/arborea8.jpg')} /></figure>
                  </div>
                </div>
            </section>

            <section className="project-one">
                <div className="project-info">
                    <h4>A - Lot 1</h4>
                    <p className="price">CHF 1'845'000</p>
                    <ul className="project-data">
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-home.svg')} /></span>
                                <span>5,5</span>
                            </div>
                            Pièces
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-garden.svg')} /></span>
                                <span>549 m2</span>
                            </div>
                            Terrain
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-plan.svg')} /></span>
                                <span>160 m2</span>
                            </div>
                            Surface habitable
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-parking.svg')} /></span>
                                <span>2</span>
                            </div>
                            Parking
                        </li>
                    </ul>
                </div>
                <div className="project-info">
                    <h4>A - Lot 2</h4>
                    <p className="price">CHF 1'960'000</p>
                    <ul className="project-data">
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-home.svg')} /></span>
                                <span>5,5</span>
                            </div>
                            Pieces
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-garden.svg')} /></span>
                                <span>1428.8 m2</span>
                            </div>
                            Terrain
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-plan.svg')} /></span>
                                <span>160 m2</span>
                            </div>
                            Surface habitable
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-parking.svg')} /></span>
                                <span>2</span>
                            </div>
                            Parking
                        </li>
                    </ul>
                </div>
            </section>
            <section className="project-one">
                <div className="project-info">
                    <h4>B - Lot 3</h4>
                    <p className="price">CHF 1'720'000</p>
                    <ul className="project-data">
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-home.svg')} /></span>
                                <span>5,5</span>
                            </div>
                            Pieces
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-garden.svg')} /></span>
                                <span>386.4 m2</span>
                            </div>
                            Terrain
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-plan.svg')} /></span>
                                <span>160 m2</span>
                            </div>
                            Surface habitable
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-parking.svg')} /></span>
                                <span>2</span>
                            </div>
                            Parking
                        </li>
                    </ul>
                </div>
                <div className="project-info">
                    <h4>B - Lot 4</h4>
                    <p className="price">VENDUE</p>
                    <ul className="project-data">
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-home.svg')} /></span>
                                <span>5,5</span>
                            </div>
                            Pieces
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-garden.svg')} /></span>
                                <span>474 m2</span>
                            </div>
                            Terrain
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-plan.svg')} /></span>
                                <span>160 m2</span>
                            </div>
                            Surface habitable
                        </li>
                        <li>
                            <div className="data-icon">
                                <span className="icon"><img src={require('../assets/images/icon-parking.svg')} /></span>
                                <span>2</span>
                            </div>
                            Parking
                        </li>
                    </ul>
                </div>
            </section>

            <BrochureModal isOpenModal={isOpenModal} setOpenModal={setIsOpenModal} />
            <div className="project-buttons">
                <a onClick={() => setIsOpenModal(true)} className="btn btn-gradient_blue btn-download"><span>Download PDF</span></a>
                <a href="/vendre" className="btn btn-gradient btn-all" target={'_blank'}><span>Tous le biens</span></a>
            </div>
          </div>
        </main>

        <section className="section-contact">
          <div className="container">
            <h2>Contactez-nous</h2>
            <div className="row">
              <div className="col-12 col-lg-4">
                <address className="contact-address">
                  NewHome Services SA
                  <br />
                  Avenue Perdtemps 23
                  <br />
                  Case Postale 1300
                  <br />
                  1260 Nyon – 1
                </address>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-phone">Tél: <a href="tel:+41223629610" onClick={() => {(window as any).dataLayer.push({event: 'phoneClick'})}}>+41 22 362 96 10</a></div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-button">
                  <a
                    className="btn btn-gradient btn-right"
                    onClick={() => {
                        setIsOpen(true);
                        (window as any).dataLayer.push({event: 'contactClick'});
                        }
                    }>
                    <span>Contactez-nous</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="about-map">
          <a
            target={'_blank'}
            href="https://www.google.com/maps/place/NewHome+Services+SA/@46.382542,6.237497,16z/data=!4m5!3m4!1s0x0:0x3a6ac63c13e0961c!8m2!3d46.382542!4d6.237497?hl=fr-FR">
            <img src={require('../assets/images/map.png')} />
          </a>
        </div>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default ArboreaPage;
