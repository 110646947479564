import React, {useEffect, useState} from 'react';
import Modal from 'react-responsive-modal';
import Input from './Input';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';

interface IBrochureModalProps {
  isOpenModal: boolean;
  setOpenModal: any;
}

const BrochureModal = ({isOpenModal, setOpenModal}: IBrochureModalProps) => {
  const {handleSubmit, register, watch, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .downloadBrochure(data)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
        }
      })
      .finally(() => {
          (window as any).dataLayer.push({
              event: "generate_lead",
              data,
              eventLabel: "Brochure"
          });
        setIsLoading(false);
      })
  };

  useEffect(() => {
    setIsSuccess(false);
  }, [isOpenModal]);

  return(
    <Modal
      open={isOpenModal}
      onClose={() => setOpenModal(false)}
      showCloseIcon={false}>
      <div className={'iziModal isAttached hasScroll'}>
        <div className={'iziModal-wrap'}>
          <div className={'iziModal-content'}>
        <div className="modal-body" style={{
          height: isSuccess ? '100vh' : 'initial'
        }}>
          <button onClick={() => setOpenModal(false)} className="modal-close">&nbsp;</button>

          <div className="modal-content">
            <div className="modal-form">
                <p className="modal-title">Download PDF</p>
                {
                  !isSuccess ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Input errors={errors} name={'firstName'} label={'Nom*' } ref={register(validation.required)} />
                      <Input errors={errors} name={'lastName'} label={'Prénom*' } ref={register(validation.required)} />
                      <Input errors={errors} name={'phone'} label={'Numéro de téléphone*' } ref={register(validation.required)} />
                      <Input errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
                      <div className="form-item_checkbox">
                        <input type="checkbox" id="accept" name={'check'} ref={register} />
                        <label htmlFor="accept">J’ai lu et j’accepte la <a target={'_blank'} href="https://wild-dots.com/fr/mentions-legales/">politique de confidentialité</a> de ce site*</label>
                      </div>
                      <button disabled={!watch('check') || isLoading} type="submit" className="btn btn-gradient">Download</button>
                    </form>
                  ) : (
                    <div className="estimate-end" style={{
                      marginTop: '15rem'
                    }
                    }>
                      Merci beaucoup d'avoir téléchargé le PDF-fichier
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </Modal>
  )
};

export default BrochureModal;
