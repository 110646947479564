import React, {useEffect, useState} from 'react';
import Modal from 'react-responsive-modal';
import {useForm} from 'react-hook-form';
import service from '../services/service';
import Input from './Input';
import useValidation from '../useHooks/useValidation';

interface INewsLetterModalProps {
  isOpen: boolean;
  onClose: any;
  defaultIsSuccess?: boolean;
}

const NewsLetterModal = ({isOpen, onClose, defaultIsSuccess = false}: INewsLetterModalProps) => {
  const {handleSubmit, register, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isSuccess, setIsSuccess] = useState(defaultIsSuccess);
  const [isLoading, setIsLoading] = useState(false);


  const onSubmit = (data: any) => {
    setIsLoading(true);
    service.subscribe(data.email)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
        }
      })
      .finally(() => {
        (window as any).dataLayer.push({
          event: "generate_lead",
          data,
          eventLabel: "Newsletter"
        });
        setIsLoading(false);
      })
  };

  useEffect(() => {
    setIsSuccess(defaultIsSuccess)
  }, [defaultIsSuccess]);

  const handleClose = () => {
    onClose();
    setIsSuccess(false);
    setIsLoading(false);
  }

  return (
    <Modal
      open={isOpen}
      classNames={{
        root: 'newsletter_modal'
      }}
      onClose={handleClose}
      showCloseIcon={false}>
      <div className={'small-modal iziModal isAttached'} id={isSuccess ? 'newsletter-thankyou' : 'newsletter'}>
        <div className={'iziModal-wrap'}>
          <div className={'iziModal-content'}>
            <div className="modal-body">
              <button onClick={handleClose} className="modal-close">&nbsp;</button>

              {
                isSuccess ? (
                  <div className="modal-content">
                    <article className="section-subscribe_modal">
                      <p className="title">Merci pour votre inscription</p>
                      <div className="section-subscribe_thankyou">
                        <div className="text">Vous recevrez désormais la newsletter de NewHome Service</div>
                        <button type="submit" className="btn btn-gradient" onClick={handleClose}>Fermer</button>
                      </div>
                    </article>
                  </div>
                ) : (
                  <div className="modal-content">
                    <article className="section-subscribe_modal">
                      <p className="title">NEWSLETTER</p>
                      Recevez des conseils et informations sur l'actualité de l'immobilier avec notre newsletter.
                    </article>
                    <form onSubmit={handleSubmit(onSubmit)} className="section-subscribe_form">
                      <Input conClassName={'input'} errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
                      <button disabled={isLoading} type="submit" className="btn btn-gradient">Je m’inscris</button>
                    </form>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default NewsLetterModal;
