import React, {useState} from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';

interface IBlogArticle1Props {

}

const BlogArticle1 = ({}: IBlogArticle1Props) => {
  const {handleSubmit, register, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  }

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service.subscribe(data.email)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (

  <div className="page-bg">
    <NewsLetterModal isOpen={isOpen} onClose={handleClose} defaultIsSuccess={isSuccess} />
  <div className="page page-about">
    <Header />
    <main className="blog-main">
      <div className="container blog-big_top">
        <p className="back-link"><Link to={'/blog'}><span>Retour au blog</span></Link></p>

        <article className="blog-big">
          <div className="blog-columns2 blog-big_first">
            <div className="blog-columns2_left">
              <h1>PRIX MOYEN AU M<sup>2</sup> CANTON DE VAUD</h1>
              <p>Prix moyen au m<sup>2</sup> par appartement calculé entre janvier 2017 et juin 2020</p>
            </div>
            <div className="blog-columns2_right">
              <figure className="pic"><img src={require('../assets/images/blog/blog2/blog2_big.png')} /></figure>
            </div>
          </div>
          <div className="blog-columns2">
            <div className="blog-columns2_left">
              <h3>UN REGAIN D’INTÉRÊT<br/>POUR LES VILLAS</h3>
              <div className="main">Certains segments de l’immobilier montrent une bonne résilience face à la pandémie
                de COVID-19. C’est le cas des villas individuelles qui voient une augmentation des demandes de
                particuliers, aidés par des taux hypothécaires qui vont rester historiquement bas.
              </div>
              <p className="highlighted">La pandémie et le semiconfinement ont conduit de nombreux citoyens à se
                questionner sur la qualité de leur habitat.</p>
              <p>D’une part, la généralisation du télétravail durant deux mois a démontré l’importance de disposer d’un
                espace dédié à ses activités professionnelles.</p>
              D’autre part, la vie à domicile a exacerbé le besoin d’espace, l’accès à un jardin ou la proximité avec la
              campagne. Dans les faits, les demandes pour les villas ou pour les grands appartements de quatre ou cinq
              pièces en PPE se multiplient, comme l’a évélé une récente étude de la Banque Cantonale de Zürich.
            </div>
            <div className="blog-columns2_right">
              <figure className="pic"><img src={require('../assets/images/blog/blog2/blog2_2.png')} /></figure>
            </div>
          </div>
        </article>
      </div>
      <div className="container">
        <article className="blog-big">
          <section className="blog-big_pic">
            <figure className="pic-big"><img src={require('../assets/images/blog/blog2/blog2_3.png')} /></figure>
            <div className="blog-columns_top">
              <h3>ANALYSE DE MARCHÉ</h3>
              <p className="highlighted">L’intérêt pour les objets bénéficiant d’un accès à un jardin privé a augmenté
                depuis la crise sanitaire.</p>
            </div>
            <div className="blog-columns">
              <div className="blog-columns_left">
                Les biens immobiliers qui répondent à ces critères sont désormais particulièrement recherchés. Selon le
                cabinet de conseil Cifi, le prix des villas a progressé en moyenne annuelle de 3,3% par rapport à août
                2019, et ce indépendamment de la crise du COVID. Cette situation est due à une demande en forte
                augmentation : 14% pour les maisons individuelles et jusqu’à 25% dans certaines villes de petite ou de
                moyenne taille en Suisse romande.
              </div>
              <div className="blog-columns_right">
                Dans la région nyonnaise, l’étude des transactions immobilières montre que les prix ont également
                augmenté lors des douze derniers mois. Le m<sup>2</sup> pour une villa se situe désormais entre CHF
                7’700 et plus de 9’700 CHF, en fonction de ses qualités et de sa localisation. Idéalement située entre
                Genève et Lausanne, la région confirme ainsi son attractivité et reste une destination particulièrement
                prisée par les acheteurs.
              </div>
            </div>
          </section>

          <div className="blog-columns">
            <div className="blog-columns_left">
              <h3>QU’EN EST-IL DES TAUX HYPOTHÉCAIRES ?</h3>
              <p className="highlighted">Si les taux d’intérêt des hypothèques à taux fixe sur le long terme avaient
                brièvement augmenté au début de la pandémie, ils sont rapidement redescendus à leurs niveaux
                historiquement bas.</p>
              <p>Avec un endettement important des Etats pour contrer les effets du coronavirus au cours des derniers
                mois, il paraît désormais peu concevable d’envisager une hausse rapide des taux d’intérêt. Les
                observateurs anticipent par conséquent une stagnation des taux hypothécaires pour les prochains
                semestres.</p>
              <p>Même si les conditions règlementaires et les exigences financières forment une barrière pour accéder à
                la propriété, les taux bas dopent la demande. Ces conditions de financement très attractives, couplées à
                des besoins révélés par la crise sanitaire, offrent des opportunités intéressantes.</p>
            </div>
            <div className="blog-columns_right">
              <h3>L’ACCÈS À LA PROPRIÉTÉ, UN RÊVE TOUJOURS VIVACE</h3>
              On constate un nombre d’objets disponibles à peu près constant sur le marché, mais le prix de vente du
              m<sup>2</sup> pour les villas est en nette augmentation depuis avril 2020. Cela démontre que le COVID-19
              n’a pas entamé la volonté des particuliers de devenir propriétaires de leur logement. Au printemps, la
              demande s’est brusquement ralentie en raison d’incertitudes bien légitimes sur l’avenir, mais également à
              cause de l’impossibilité de visiter des biens. Toutefois, les premières mesures du déconfinement ont
              révélé un attrait grandissant pour les villas avec des visites en augmentation.
            </div>
          </div>
        </article>
      </div>
    </main>

    <section className="section-subscribe">
      <div className="container">
        <article className="section-subscribe_left">
          <p className="title">NEWSLETTER</p>
          Recevez des conseils et informations sur l'actualité de l'immobilier avec notre newsletter.
        </article>
        <form onSubmit={handleSubmit(onSubmit)} className="section-subscribe_form">
          <Input conClassName={'input'} errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
          <button disabled={isLoading} type="submit" className="btn btn-gradient">Je m’inscris</button>
        </form>
      </div>
    </section>

    <section className="section-conseil">
        <div className="container">
          <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
        </div>
    </section>

    <Footer />
  </div>
  </div>
  )
};

export default BlogArticle1;
