export default [
  {
    id: 1,
    title: "Immeubles",
    properties: [
      {
        title: "Résidences Aloïs – Morges (2025)",
        description:
          "Réalisation de deux immeubles en PPE de 9 appartements de 2 ½ à 5 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/1.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/2.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/3.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/4.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/5.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/6.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidencesAloïsMorges(2025)/7.jpg"),
        ],
        link: "Infos vente",
        href: "https://residences-alois.ch/"
      },
      {
        title: "Résidence Symbiose – Le Mont-sur-Lausanne (2025)",
        description:
          "Réalisation de 8 immeubles en PPE de 32 appartements de 3 ½ à 4 ½ pièces et de 4 villas jumelées avec parking.",
        images: [
          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/1.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/2.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/3.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/4.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/5.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/6.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/7.jpg"),

          require("../assets/images/portfolio/Immeubles/RésidenceSymbioseLeMontsurLausanne(2025)/8.jpg"),

        ],
        link: "Infos vente",
        href: "https://residence-symbiose.ch/"
      },
      {
        title: "Résidence Tannay Horizon — Tannay (2021)",
        description:
          "Réalisation de deux immeubles en PPE de 16 appartements de 2 pièces à 4 ½ pièces.",
        images: [
          // require('../assets/images/portfolio/Immeubles/Résidence Tannay Horizon  Tannay (2021)/image-1-2.jpg'),

          require("../assets/images/portfolio/Immeubles/Résidence Tannay Horizon  Tannay (2021)/image-4.jpeg"),

          require("../assets/images/portfolio/Immeubles/Résidence Tannay Horizon  Tannay (2021)/image-3-1.jpeg"),

          require("../assets/images/portfolio/Immeubles/Résidence Tannay Horizon  Tannay (2021)/image-2-1.jpeg"),

          require('../assets/images/portfolio/Immeubles/Résidence Tannay Horizon  Tannay (2021)/image-1.jpeg')
        ],
      },
      {
        title: "Résidence EchoParc — Bussigny (2020)",
        description:
          "Réalisation de deux immeubles en PPE de 45 appartements du studio au 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSCN5725.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSC8665.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSC8670.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSC8677.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSC8702.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSCN5731.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSCN5734.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSCN5735.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSCN5738.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence EchoParc  Bussigny (2020)/3129 Echo-Parc_DSCN5744.jpg"),
        ],
      },
      {
        title: "Résidence Champs Soleil — Corcelles-près-Concise (2019)",
        description:
          "Réalisation d'un immeuble en PPE de 7 appartements de 3 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence Champs Soleil Corcelles-près-Concise (2020)/Corcelles_3.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Champs Soleil Corcelles-près-Concise (2020)/Corcelles_5.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Champs Soleil Corcelles-près-Concise (2020)/Corcelles_6.jpg"),
        ],
      },
      {
        title: "Résidence du Vieux Battoir — Mathod (2019)",
        description:
          "Réalisation de trois immeubles en PPE de 18 appartements de 2 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/1.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/2.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/3.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/4.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/5.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/6.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vieux Battoir   Mathod (2020)/7.jpg"),
        ],
      },
      {
        title: "Les Jardins du Bourg — Eysins (2019)",
        description:
          "Réalisation de deux immeubles en PPE de 23 appartements de 2 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Les Jardins du Bourg Eysins (2019)/1-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Les Jardins du Bourg Eysins (2019)/2-6.jpg"),

          require("../assets/images/portfolio/Immeubles/Les Jardins du Bourg Eysins (2019)/3-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Les Jardins du Bourg Eysins (2019)/4-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Les Jardins du Bourg Eysins (2019)/5.jpg"),
        ],
      },
      {
        title: "Route de Prilly 10 — Lausanne (2018)",
        description:
          "Réalisation d'un immeuble locatif de 14 appartements du studio au 3 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/1-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/2-3.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/1-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/2-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/3-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/4-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/5-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Route de Prilly 10  Lausanne  (2018)/6-1.jpg"),
        ],
      },
      {
        title: "Résidence le Soleal — Gland (2017)",
        description:
          "Réalisation d'un immeuble en PPE de 18 appartements de 2 pièces à 4 ½ pièces. ",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/2-4.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/3-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/1-3.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/1-4.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/2-5.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/3-3.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/4-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/5-2.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence le Soleal  Gland (2018)/6-2.jpg"),
        ],
      },
      {
        title: "Résidence BellaVista — Pully (2017)",
        description:
          'Réalisation d’un immeuble "Minergie" en PPE de 5 appartements de 2 ½ pièces à 4 ½ pièces et d\'un attique de 5 ½ pièces avec mezzanine.',
        images: [
          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/1-5.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/2-6.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/1.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/2-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/3.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/4.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/5.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/6.jpg"),

          require("../assets/images/portfolio/Immeubles/Residence BellaVista  Pully (2017)/7.jpg"),
        ],
      },
      {
        title: "Résidence Magellan — Pully (2016)",
        description:
          'Réalisation d’un immeuble "Minergie" en PPE de 6 appartements de 3 ½ pièces et d\'un attique de 6 pièces avec mezzanine. ',
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence Magellan   Pully (2016)/Entreprise-Construction-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Magellan   Pully (2016)/Entreprise-Construction-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Magellan   Pully (2016)/Societe-Construction-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Magellan   Pully (2016)/Societe-Construction-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Magellan   Pully (2016)/Societe-Renovation-Vaud.jpg"),
        ],
      },
      {
        title: "Résidence Parc et Lac — St-Sulpice (2016)",
        description:
          "Réalisation d’un immeuble en PPE de 7 appartements de 3 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence Parc et Lac   St-Sulpice (2016)/Entreprise-Valorisation-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Parc et Lac   St-Sulpice (2016)/Societe-Valorisation-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Parc et Lac   St-Sulpice (2016)/Societe-Valorisation-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence Parc et Lac   St-Sulpice (2016)/Entreprise-Valorisation-Immobilier-Vaud.jpg"),
        ],
      },
      {
        title: "Résidence du Vignoble — Gilly (2015)",
        description:
          "Réalisation de deux immeubles en PPE de 16 appartements de 2 ½ pièces à 5 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence du Vignoble     Gilly (VD) (2015)/Immobilier-Construction-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vignoble     Gilly (VD) (2015)/Immobilier-Valorisation-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vignoble     Gilly (VD) (2015)/Immobilier-Expertise-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vignoble     Gilly (VD) (2015)/Expertise-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence du Vignoble     Gilly (VD) (2015)/Expert-Immobilier-Vaud.jpg"),
        ],
      },
      {
        title: "Immeubles des Buis A et B — La Sarraz (2014)",
        description:
          "Réalisation de deux immeubles locatifs de 16 appartements de 3 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Immeubles des Buis A et B  La Sarraz (2014)/Construction-Projet-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Immeubles des Buis A et B  La Sarraz (2014)/Entreprise-Construction-Projets-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Immeubles des Buis A et B  La Sarraz (2014)/Societe-Construction-Projets-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Immeubles des Buis A et B  La Sarraz (2014)/Societe-Construction-Projet-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Immeubles des Buis A et B  La Sarraz (2014)/Entreprise-Construction-Projet-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Immeubles des Buis A et B  La Sarraz (2014)/Renovation-Immobilier-Vaud-1.jpg"),
        ],
      },
      {
        title: "Résidence les Hibiscus — Gland (2013)",
        description:
          "Réalisation d’un immeuble en PPE de 8 appartements de 2 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence les Hibiscus     Gland (2013)/Immobilier-Analyse-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence les Hibiscus     Gland (2013)/Immobilier-Transformation-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence les Hibiscus     Gland (2013)/Analyse-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence les Hibiscus     Gland (2013)/Immobilier-Projet-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence les Hibiscus     Gland (2013)/Projets-Immobilier-Vaud.jpg"),
        ],
      },
      {
        title: "Résidence les Peupliers — Préverenges (2011)",
        description:
          "Réalisation d’un immeuble en PPE de 12 appartements de 2 ½ pièces à 4 ½ pièces avec mezzanine.",
        images: [
          require("../assets/images/portfolio/Immeubles/Résidence les Peupliers Préverenges (2011)/Entreprise-Renovation-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence les Peupliers Préverenges (2011)/Renovation-Entreprise-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Résidence les Peupliers Préverenges (2011)/Renovation-Societe-Vaud.jpg"),
        ],
      },
      {
        title: "Résidence du Cèdre — Mies (2010)",
        description:
          'Réalisation d’un immeuble "Minergie" en PPE de 9 appartements de 3 ½ pièces et à 5 ½ pièces.',
        images: [
          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Immobilier-Execution-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Immobilier-Projet-Vaud-1.jpg"),

          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Immobilier-Travaux-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Direction-Travaux-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Immobilier-Direction-de-Travaux-Vaux.jpg"),

          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Immobilier-Direction-Travaux-Vaux.jpg"),

          require("../assets/images/portfolio/Immeubles/La Résidence du Cèdre  Mies (VD) (2010)/Immobilier-Projets-Vaud.jpg"),
        ],
      },
      {
        title: "Résidence Soleil du Jura — Etoy (2009)",
        description:
          "Réalisation d’un immeuble en PPE de 8 appartements de 3 ½ pièces à 4 ½ pièces.",
        images: [
          require("../assets/images/portfolio/Immeubles/Soleil du Jura  Etoy  (2010)/Immobilier-Renovation-Vaud.jpg"),

          require("../assets/images/portfolio/Immeubles/Soleil du Jura  Etoy  (2010)/Immobilier-Vaud-1.jpg"),

          require("../assets/images/portfolio/Immeubles/Soleil du Jura  Etoy  (2010)/Construction-Immobiliere-Vaud-1.jpg"),
        ],
      },
    ],
  },

  {
    id: 2,
    title: "Villas",
    properties: [
      {
        title: "Villas 4 – Suscévaz (2024)",
        description: "Réalisation de quatre villas contigües.",
        images: [
          require("../assets/images/portfolio/Villas/Villas4Suscévaz(2024)/1.jpg"),

          require("../assets/images/portfolio/Villas/Villas4Suscévaz(2024)/2.jpg"),

          require("../assets/images/portfolio/Villas/Villas4Suscévaz(2024)/3.jpg"),

          require("../assets/images/portfolio/Villas/Villas4Suscévaz(2024)/4.jpg"),

          require("../assets/images/portfolio/Villas/Villas4Suscévaz(2024)/5.jpg"),
        ],
        link: "Infos vente",
        href: "https://www.square-immobilier.ch/fr/objects/detail/4276559?search_id=c55bfb73c4677da16e8719875e41d403"
      },
      {
        title: "Chemin des Ecaravez 26-28 – Belmont (2024)",
        description: "Réalisation de deux villas contigües.",
        images: [
          require("../assets/images/portfolio/Villas/ChemindesEcaravez26-28Belmont (2024)/1.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesEcaravez26-28Belmont (2024)/2.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesEcaravez26-28Belmont (2024)/3.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesEcaravez26-28Belmont (2024)/4.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesEcaravez26-28Belmont (2024)/5.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesEcaravez26-28Belmont (2024)/6.jpg"),
        ],
        link: "Infos vente",
        href: "https://www.square-immobilier.ch/fr/objects/detail/4333777?search_id=c55bfb73c4677da16e8719875e41d403"
      },
      {
        title: "Le Poyet – Aubonne (2025)",
        description: "Réalisation de deux villas jumelées.",
        images: [
          require("../assets/images/portfolio/Villas/LePoyetAubonne(2025)/1.jpg"),

          require("../assets/images/portfolio/Villas/LePoyetAubonne(2025)/2.jpg"),

          require("../assets/images/portfolio/Villas/LePoyetAubonne(2025)/3.jpg"),

          require("../assets/images/portfolio/Villas/LePoyetAubonne(2025)/4.jpg"),

          require("../assets/images/portfolio/Villas/LePoyetAubonne(2025)/5.jpg"),
        ],
      },
      {
        title: "Chemin du Nant 3 — Commugny (2012)",
        description: "Réalisation de deux villas jumelées par les garages.",
        images: [
          require("../assets/images/portfolio/Villas/CheminduNantCommugny(2012)/Construction-Villas-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/CheminduNantCommugny(2012)/Renovation-Villas-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/CheminduNantCommugny(2012)/Construction-Villa-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/CheminduNantCommugny(2012)/Expertise-Villas-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/CheminduNantCommugny(2012)/Projet-Villa-Vaud.jpg"),
        ],
      },
      {
        title: "Résidence du Chêne — Le Mont-sur-Lausanne (2012)",
        description: "Réalisation de six villas jumelées avec garages.",
        images: [
          require("../assets/images/portfolio/Villas/LeMontsurLausanneResidenceduChene(2012)/P1000857.jpg"),

          require("../assets/images/portfolio/Villas/LeMontsurLausanneResidenceduChene(2012)/P1000858.jpg"),

          require("../assets/images/portfolio/Villas/LeMontsurLausanneResidenceduChene(2012)/P1000863.jpg"),

          require("../assets/images/portfolio/Villas/LeMontsurLausanneResidenceduChene(2012)/P1000864.jpg"),

          require("../assets/images/portfolio/Villas/LeMontsurLausanneResidenceduChene(2012)/P1000867-1.jpg"),
        ],
      },
      {
        title: "Les Terrasses du Muids — Arzier (2011)",
        description: "Réalisation de six villas jumelées.",
        images: [
          require("../assets/images/portfolio/Villas/LesTerrassesduMuidsArzier(2012)/Entreprise-Pilotage-Projets-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/LesTerrassesduMuidsArzier(2012)/Societe-Pilotage-Projet-Immobilier-Vaud.jpg"),
        ],
      },
      {
        title: "Chemin des Noyers 2 — Tannay (2011)",
        description: "Réalisation de deux villas jumelées avec garages.",
        images: [
          require("../assets/images/portfolio/Villas/ChemindesNoyersTannay(2011)/Projet-Construction-Villa-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesNoyersTannay(2011)/Projets-Villas-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesNoyersTannay(2011)/Projet-Construction-Villas-Vaud.jpg"),

          require("../assets/images/portfolio/Villas/ChemindesNoyersTannay(2011)/Projets-Villa-Vaud-1.jpg"),
        ],
      },
    ],
  },

  {
    id: 3,
    title: "Rénovations",
    properties: [
      {
        title: 'Habitation de village "La Grange" — Gilly (2013)',
        description:
          "Transformation d'une ancienne grange en un unique logement.",
        images: [
          require("../assets/images/portfolio/Renovations/LaGrangeGilly(VD)(2013)/Societe-Immobilier-Transformation-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/LaGrangeGilly(VD)(2013)/Societe-Immobiliere-Transformation-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/LaGrangeGilly(VD)(2013)/Societe-Transformation-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/LaGrangeGilly(VD)(2013)/Societe-Transformation-Immobiliere-Vaud.jpg"),
        ],
      },
      {
        title: "Château de Mézery — Jouxtens-Mézery (2012)",
        description: "Restauration d'une maison de Maître.",
        images: [
          require("../assets/images/portfolio/Renovations/MaisondeMaîtreJouxtens-Mezery(2012)/Entreprise-Execution-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisondeMaîtreJouxtens-Mezery(2012)/Societe-Execution-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisondeMaîtreJouxtens-Mezery(2012)/Societe-Execution-Immobilier-Vaud.jpg"),
        ],
      },
      {
        title: 'Habitation de village "Maison Vigneronne" — Gilly (2010)',
        description: "Transformation et rénovation en un unique logement.",
        images: [
          require("../assets/images/portfolio/Renovations/MaisonVigneronneGilly(VD)(2011)/Entreprise-Analyse-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisonVigneronneGilly(VD)(2011)/Entreprise-Projet-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisonVigneronneGilly(VD)(2011)/Entreprise-Projets-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisonVigneronneGilly(VD)(2011)/Societe-Analyse-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisonVigneronneGilly(VD)(2011)/Societe-Analyse-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/MaisonVigneronneGilly(VD)(2011)/Societe-Projet-Immobilier-Vaud.jpg"),
        ],
      },
      {
        title: 'Habitation de village "La Forge" — Commugny (2012)',
        description:
          "Transformation de la forge historique du village avec la création de 4 appartements en PPE.",
        images: [
          require("../assets/images/portfolio/Renovations/RueduMartinetCommugny(VD)(2011)/Societe-Expertise-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RueduMartinetCommugny(VD)(2011)/Societe-Expertise-Immobiliere-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RueduMartinetCommugny(VD)(2011)/Entreprise-Expert-Immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RueduMartinetCommugny(VD)(2011)/Entreprise-Expertise-Immobiliere-Vaud.jpg"),
        ],
      },
      {
        title: "Rue des Fossés 21 — Morges (2008)",
        description:
          "Transformation d'une maison de ville et création de 2 appartements en PPE.",
        images: [
          require("../assets/images/portfolio/Renovations/RuedesFossesMorges(2008)/Entreprise-Direction-Travaux-immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RuedesFossesMorges(2008)/Societe-Direction-Travaux-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RuedesFossesMorges(2008)/Entreprise-Direction-Travaux-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RuedesFossesMorges(2008)/Societe-Direction-Travaux-immobilier-Vaud.jpg"),

          require("../assets/images/portfolio/Renovations/RuedesFossesMorges(2008)/Entreprise-Direction-de-Travaux-Vaud.jpg"),
        ],
      },
    ],
  },
];
