import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import propeties from '../mockData/propeties';
import {generateClassName} from '../utils/generateClassName';
import {Link, RouteComponentProps} from 'react-router-dom';
import IncludeScript from '../components/IncludeScript';

interface IPortfolioProps extends RouteComponentProps<any> {

}

const PortfolioPage = ({match}: IPortfolioProps) => {
  let {id = 1} = match.params;
  id = +id;
  const item: any = propeties.find(p => p.id === id)!;

  const scrool = (e: any) => {
    e.currentTarget.scrollIntoView({
      inline: 'center',
      block: 'center'
    });
  };

  return (
    <div className="page-bg">
      <div className="page">
        <Header/>
        <main className="portfolio-page">
          <div className="portfolio-first">
            <div className="container">
              <h1>Portfolio</h1>

              <article className="intro-text">
                Notre portfolio professionnel présente un éventail de nos projets
                immobiliers. Vous y trouverez des exemples concrets de notre travail,
                reflétant notre engagement envers la qualité et l'efficacité. Notre
                expérience s'étend des projets de construction neufs aux rénovations
                complexes, en passant par le développement de résidences. Chaque
                projet illustre notre capacité à répondre aux exigences spécifiques de
                nos clients tout en transformant des idées en structures viables. Nous
                vous invitons à examiner notre portfolio pour évaluer notre
                compétence et notre approche méthodique dans le secteur
                immobilier.
              </article>
              <div className="portfolio-top">
                <div className="scrollbar-outer">
                  <div className="row">
                   <div className="col-lg-4">
                      <article className="portfolio-top_item portfolio-top_open">
                        <span className="portfolio-top_icon"><img src={require('../assets/images/icon-open.svg')}
                                                                  className="icon-open"/></span>
                        <p className="text">Créé en 2007</p>
                      </article>
                    </div>
                    <div className="col-lg-4">
                      <article className="portfolio-top_item portfolio-top_release">
                      <span className="portfolio-top_icon"><img src={require('../assets/images/icon-release.svg')}
                                                                className="icon-release"/></span>
                        <p className="text">+300 logements</p>
                      </article>
                    </div>
                    <div className="col-lg-4">
                      <article className="portfolio-top_item portfolio-top_city">
                        <span className="portfolio-top_icon"><img src={require('../assets/images/icon-city.svg')}
                                                                  className="icon-city"/></span>
                        <p className="text">+50 <br/>Immeubles</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="portfolio-tabs">
              <div className="scrollbar-outer">
                <ul className="tabs">
                  {
                    propeties.map((p, i) => {
                      return <li key={i} className={generateClassName('tab', {
                        active: p.id === id
                      })}><Link onClick={scrool} to={'/portfolio/' + p.id}>{p.title}</Link></li>
                    })
                  }
                </ul>
              </div>

              <div className="tabs-content">
                <div className="tabs-one">
                  <div className="row">
                    {
                      item.properties.map((p:any, index: number) => {
                        return (
                          <div key={index} className="col-12 col-md-6">
                            <Link onClick={() =>  window.scrollTo( 0,0 )} to={`/portfolio/${item.id}-${index}`}>
                              <div className="portfolio-one">
                                <span className="counter"><span>+{p.images.length}</span></span>
                                <figure className="portfolio-one_pic"><img src={p.images[0]}/></figure>
                                <div className="portfolio-one_name">
                                  {p.title}
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer/>
      </div>
      <IncludeScript name={'scroll'}/>
    </div>
  )
};

export default PortfolioPage;
