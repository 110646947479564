import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactModal from '../components/ContactModal';

const AboutPage = () => {
  const [isOpen, setIsOpen] = useState(false);


  return (
    <div className="page-bg">
      <div className="page">
        <Header />
        <ContactModal isOpen={isOpen} setOpen={setIsOpen} />
        <main className="static-main">
          <div className="container">
            <h1>Déclaration de protection des données</h1>

            <article className="static-article">
                <div className="static-div">
                    <p className="subtitle">Introduction</p>
                   Nous attachons une grande importance à la protection de vos données personnelles et respectons votre vie privée. Cette déclaration de protection des données explique comment nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre site internet.
                </div>

                <div className="static-div">
                    <p className="subtitle">Responsable du traitement des données</p>
                    Nom de l'entreprise : NewHome Services SA<br/>
                    Adresse : Av. Perdtemps 23, 1260 Nyon<br/>
                    E-mail :  <a href="mailto:info@newhomeservices.ch" onClick={() => {(window as any).dataLayer.push({event: 'emailClick'})}}>info@newhomeservices.ch</a>
                </div>

                <div className="static-div">
                    <p className="subtitle">Collecte des données</p>
                    Nous collectons les données personnelles que vous nous fournissez directement lorsque vous utilisez notre site internet, notamment lors de :
                    <ul>
                       <li>-	L’utilisation de notre formulaire de contact</li>
                       <li>-	La demande de brochure sur notre page de vente</li>
                    </ul>
                    Les données collectées peuvent inclure :
                    <ul>
                       <li>-	Nom et prénom</li>
                       <li>-	Adresse e-mail</li>
                       <li>-	Adresse postale</li>
                       <li>-	Numéro de téléphone</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Utilisation des données</p>
                    Les données collectées sont utilisées pour :
                    <ul>
                       <li>-	Fournir et gérer nos services</li>
                       <li>-	Communiquer avec vous</li>
                       <li>-	Vous envoyer des informations</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Partage des données</p>
                    Nous ne partageons pas vos données personnelles avec des tiers, sauf dans les cas suivants :
                    <ul>
                       <li>-	Avec votre consentement explicite</li>
                       <li>-	Pour se conformer à des obligations légales</li>
                       <li>-	Avec des prestataires de services tiers qui agissent en notre nom et respectent des mesures de sécurité adéquates</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Protection des données</p>
                    Nous prenons des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre tout accès non autorisé, utilisation abusive, perte ou destruction. Ces mesures incluent le cryptage des données et des protocoles de sécurité pour nos systèmes et bases de données. De plus, vos données sont stockées dans le CRM Immomig, hébergé en Suisse.
                </div>

                <div className="static-div">
                    <p className="subtitle">Vos droits</p>
                    Vous avez le droit de :
                    <ul>
                       <li>-	Accéder à vos données personnelles et obtenir des informations sur leur traitement</li>
                       <li>-	Rectifier des données incorrectes ou incomplètes</li>
                       <li>-	Demander l’effacement de vos données personnelles dans certaines conditions</li>
                       <li>-	Limiter le traitement de vos données dans certaines conditions</li>
                       <li>-	Vous opposer au traitement de vos données personnelles</li>
                       <li>-	Demander la portabilité de vos données</li>
                       <li>Pour exercer ces droits, vous pouvez nous contacter à l’adresse e-mail mentionnée ci-dessus.</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Cookies et technologies similaires</p>
                    Nous utilisons des cookies et des technologies similaires pour améliorer la fonctionnalité de notre site et personnaliser votre expérience. Vous pouvez configurer votre navigateur pour refuser les cookies ou être averti avant de les accepter. Cependant, certaines fonctionnalités de notre site peuvent ne pas fonctionner correctement sans l’utilisation de cookies.
                </div>

                <div className="static-div">
                    <p className="subtitle">Modifications de la déclaration de protection des données</p>
                    Nous nous réservons le droit de modifier cette déclaration de protection des données à tout moment. Les modifications seront publiées sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette déclaration pour rester informé des mesures de protection des données que nous appliquons.
                </div>

                <div className="static-div">
                    <p className="subtitle">Contact</p>
                    <p>Pour toute question ou demande concernant la protection de vos données personnelles, vous pouvez nous contacter à l’adresse suivante :</p>
                    Nom de l'entreprise : NewHome Services SA<br/>
                    Adresse : Av. Perdtemps 23, 1260 Nyon<br/>
                    E-mail : <a href="mailto:info@newhomeservices.ch" onClick={() => {(window as any).dataLayer.push({event: 'emailClick'})}}>info@newhomeservices.ch</a>
                </div>

                <p className="static-date">Date de mise à jour : 08.07.2024</p>
            </article>

            <h1>Data Protection Declaration</h1>

            <article className="static-article">
                <div className="static-div">
                    <p className="subtitle">Introduction</p>
                    We place great importance on protecting your personal data and respecting your privacy. This data protection declaration explains how we collect, use, and protect your personal information when you use our website.
                </div>

                <div className="static-div">
                    <p className="subtitle">Data Controller</p>
                    Company Name: NewHome Services SA<br/>
                    Address: Av. Perdtemps 23, 1260 Nyon<br/>
                    Email: <a href="mailto:info@newhomeservices.ch" onClick={() => {(window as any).dataLayer.push({event: 'emailClick'})}}>info@newhomeservices.ch</a>
                </div>

                <div className="static-div">
                    <p className="subtitle">Data Collection</p>
                    We collect the personal data you provide directly when you use our website, particularly when:
                    <ul>
                       <li>-	Using our contact form</li>
                       <li>-	Requesting a brochure on our sales page</li>
                    </ul>
                    The data collected may include:
                    <ul>
                       <li>-	First and last name</li>
                       <li>-	Email address</li>
                       <li>-	Postal address</li>
                       <li>-	Phone number</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Data Usage</p>
                    The collected data is used to:
                    <ul>
                       <li>-	Provide and manage our services</li>
                       <li>-	Communicate with you</li>
                       <li>-	Send you information</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Data Sharing</p>
                    We do not share your personal data with third parties, except in the following cases:
                    <ul>
                       <li>-	With your explicit consent</li>
                       <li>-	To comply with legal obligations</li>
                       <li>-	With third-party service providers acting on our behalf and adhering to appropriate security measures</li>
                    </ul>
                </div>

                <div className="static-div">
                    <p className="subtitle">Data Protection</p>
                    We take appropriate technical and organizational measures to protect your personal data against unauthorized access, misuse, loss, or destruction. These measures include data encryption and security protocols for our systems and databases. Additionally, your data is stored in the CRM Immomig, hosted in Switzerland.
                </div>

                <div className="static-div">
                    <p className="subtitle">Your Rights</p>
                    You have the right to:
                    <ul>
                       <li>-	Access your personal data and obtain information about its processing</li>
                       <li>-	Rectify incorrect or incomplete data</li>
                       <li>-	Request the deletion of your personal data under certain conditions</li>
                       <li>-	Restrict the processing of your data under certain conditions</li>
                       <li>-	Object to the processing of your personal data</li>
                       <li>-	Request data portability</li>
                    </ul>
                    To exercise these rights, you can contact us at the email address mentioned above.
                </div>

                <div className="static-div">
                    <p className="subtitle">Cookies and Similar Technologies</p>
                    We use cookies and similar technologies to enhance the functionality of our site and personalize your experience. You can configure your browser to refuse cookies or to be notified before accepting them. However, some features of our site may not function properly without the use of cookies.
                </div>

                <div className="static-div">
                    <p className="subtitle">Changes to the Data Protection Declaration</p>
                    We reserve the right to modify this data protection declaration at any time. Changes will be published on this page with an update date. We encourage you to regularly review this declaration to stay informed about the data protection measures we apply.
                </div>

                <div className="static-div">
                    <p className="subtitle">Contact</p>
                    <p>For any questions or requests regarding the protection of your personal data, you can contact us at the following address:</p>
                    Company Name: NewHome Services SA<br/>
                    Address: Av. Perdtemps 23, 1260 Nyon  <br/>
                    Email: <a href="mailto:info@newhomeservices.ch" onClick={() => {(window as any).dataLayer.push({event: 'emailClick'})}}>info@newhomeservices.ch</a>
                </div>

                <p className="static-date">Update Date: 08.07.2024</p>
            </article>
          </div>
        </main>

        <section className="section-contact">
          <div className="container">
            <h2>Contactez-nous</h2>
            <div className="row">
              <div className="col-12 col-lg-4">
                <address className="contact-address">
                  NewHome Services SA
                  <br />
                  Avenue Perdtemps 23
                  <br />
                  Case Postale 1300
                  <br />
                  1260 Nyon – 1
                </address>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-phone">Tél: <a href="tel:+41223629610" onClick={() => {(window as any).dataLayer.push({event: 'phoneClick'})}}>+41 22 362 96 10</a></div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="contact-button">
                  <a
                    className="btn btn-gradient btn-right"
                    onClick={() => {
                        setIsOpen(true);
                        (window as any).dataLayer.push({event: 'contactClick'});
                        }
                    }>
                    <span>Contactez-nous</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="about-map">
          <a
            target={'_blank'}
            href="https://www.google.com/maps/place/NewHome+Services+SA/@46.382542,6.237497,16z/data=!4m5!3m4!1s0x0:0x3a6ac63c13e0961c!8m2!3d46.382542!4d6.237497?hl=fr-FR">
            <img src={require('../assets/images/map.png')} />
          </a>
        </div>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default AboutPage;
