import {instanceWD} from './instance';
import {AxiosResponse} from 'axios';

const service = {

    contactForm: (data: any) => {
      const formData = new FormData();
      formData.append('project', 'newhomeservices');
      formData.append('source', 'landing');
      formData.append('action', 'contact_request');
      formData.append('message', data.message || '');
      formData.append('contact[first_name]', data.firstName);
      formData.append('contact[last_name]', data.lastName);
      formData.append('contact[email]', data.email);
      formData.append('contact[phone]', data.phone);
      formData.append('marketing[source]', data.marketing || '');

      const config: any = {
        method: 'post',
        url: 'submitRequest',
        data : formData,
        redirect: 'follow'
      };

      return customFetch(() => instanceWD(config));
    },
    estimateForm: (data: any) => {
      const formData = new FormData();
      formData.append('project', 'newhomeservices');
      formData.append('source', 'landing');
      formData.append('action', 'land_estimation_request');
      formData.append('contact[full_name]', data.fullName);
      formData.append('contact[email]', data.email);
      formData.append('contact[phone]', data.phone);
      formData.append('land[relation_to_owner]', data.relationOwner || '');
      formData.append('land[address]', data.address || '');
      formData.append('land[registry_number]', data.registryNumber || '');
      formData.append('marketing[source]', data.marketing || '');


      const config: any = {
        method: 'post',
        url: 'submitRequest',
        data : formData,
        redirect: 'follow'
      };

      return customFetch(() => instanceWD(config));
  },
  subscribe: (email: string) => {
    const formData = new FormData();
    formData.append('project', 'newhomeservices');
    formData.append('source', 'landing');
    formData.append('action', 'newsletter_subscription');
    formData.append('contact[email]', email);

    const config: any = {
      method: 'post',
      url: 'submitRequest',
      data : formData,
      redirect: 'follow'
    };

    return customFetch(() => instanceWD(config));
  },
    downloadBrochure: (data: any) => {
        const formData = new FormData();
        formData.append('project', 'newhomeservices');
        formData.append('source', 'landing');
        formData.append('action', 'brochure_download');
        formData.append('message', data.message || '');
        formData.append('contact[first_name]', data.firstName);
        formData.append('contact[last_name]', data.lastName);
        formData.append('contact[email]', data.email);
        formData.append('contact[phone]', data.phone);

        const config: any = {
            method: 'post',
            url: 'submitRequest',
            data : formData,
            redirect: 'follow'
        };

        return customFetch(() => instanceWD(config));
    }
};

const customFetch = async <T>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();
    return {
      success: true,
      data: res.data,
      code: res.status,
    };
  } catch (e) {
    console.error({e});
    if (e.response && e.response.data && e.response.data.message) {
      throw {
        success: false,
        data: e.response.data.message,
        code: e.response.status,
      };
    }
    throw {
      success: false,
      data: 'error',
      code: 500,
    };
  }
};

export default service;
