const useValidation = () => {
  const validation = {
    email: {
      required: 'Ce champ est obligatoire',
      pattern: {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Mauvais format d’adresse mail'
      }
    },
    required: {
      required: 'Ce champ est obligatoire'
    },
    oneOfThemRequired: (value1) => ({
      validate: (value) => {
        return !value && !value1 ? 'Ce champ est obligatoire' : undefined;
      },
    })
  };

  return validation;
};

export default useValidation;
