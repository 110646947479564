import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useForm } from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';
import { Link } from 'react-router-dom';

interface IBlogArticle1Props {}

const BlogArticle7 = ({}: IBlogArticle1Props) => {
  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service
      .subscribe(data.email)
      .then((res) => {
        if (res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-bg">
      <NewsLetterModal
        isOpen={isOpen}
        onClose={handleClose}
        defaultIsSuccess={isSuccess}
      />
      <div className="page page-about">
        <Header />
        <main className="blog-main">
          <div className="container blog-big_top">
            <p className="back-link">
              <Link to={'/blog'}>
                <span>Retour au blog</span>
              </Link>
            </p>

            <article className="blog-big">
              <div className="blog-columns2 blog-big_first">
                <div className="blog-columns2_left">
                  <h1>
                    Comment tirer le meilleur parti de son bien immobilier ?
                    Valorisation ou vente de sa propriété
                  </h1>
                  <p>&nbsp;</p>
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog7/blog7_big.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2">
                <div className="blog-columns2_left">
                  <div className="main">
                    La vente de sa propriété ou de son terrain est toujours une
                    décision difficile. Des alternatives sont possibles,
                    notamment la rénovation ou la démolition et construction de
                    logements via un développement immobilier. Quand on est
                    confronté à cette décision, il est essentiel de se poser les
                    bonnes questions et d’être conseillé par des experts qui
                    sauront vous guider.
                  </div>
                  <p className="highlighted">
                    Rénovation et développement immobilier
                  </p>
                  <p>
                    Lorsque qu’un propriétaire envisage de valoriser son bien
                    immobilier ou foncier, plusieurs options s’offrent à lui. Il
                    peut d’abord rénover le bâti. Cette alternative est
                    intéressante, car les changements apportés à son logement
                    peuvent faire l’objet de déductions fiscales. Le bien rénové
                    gagne par ailleurs en valeur et il est, dans le meilleur des
                    cas, possible d’en tirer une plus-value lors d’une vente par
                    la suite. L’importance des rénovations à effectuer ne doit
                    cependant pas être sous-estimée : pour un bien vétuste ou
                    vieillissant les coûts engendrés sont souvent dissuasifs.
                    Pendant la durée des travaux, il peut par ailleurs être
                    difficile de continuer à vivre dans le logement concerné à
                    cause des désagréments.
                  </p>
                  Pour les propriétaires, le développement immobilier et la
                  construction de logements sont une option à envisager. Il
                  s’agit néanmoins d’une entreprise de grande ampleur et de
                  longue haleine, difficile à gérer pour un particulier. La
                  coordination des multiples intervenants (architectes,
                  entreprise de construction, autorités, etc.) autour d’un
                  projet de ce type est complexe et il est nécessaire de
                  connaître les différentes réglementations en vigueur. D’autre
                  part, un problème peut survenir : pour le propriétaire qui
                  réside dans sa maison ou son appartement, la démolition est un
                  passage difficile car il ne profite plus de son logement
                  jusqu’à ce que les nouveaux bâtiments soient construits. Dans
                  ce cas de figure, la vente de son bien immobilier semble plus
                  simple d’un point de vue pratique.
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog7/blog7_2.png')}
                    />
                  </figure>
                </div>
              </div>
              <div className="blog-columns2">
                <div className="blog-columns2_left">
                  <p className="highlighted">
                    Vendre son terrain ou sa propriété à un expert
                  </p>
                  <p>
                    L’immobilier résidentiel a connu une hausse de prix
                    considérable depuis plusieurs années, encore renforcée par
                    la pandémie et l’intérêt grandissant pour les villas. Les
                    montants des transactions immobilières n’ont jamais été
                    aussi élevés. La vente de sa propriété est dès lors devenue
                    une opportunité de choix. Une récente étude de Wüest Partner
                    montre une augmentation de prix de 4% pour les appartements
                    en PPE et les maisons individuelles dans le canton de Vaud
                    depuis le début de l’année. À titre d’exemple, le m2 se
                    négocie aujourd’hui en moyenne aux alentours de CHF 12'000.-
                    à Nyon et de CHF 11'000.- à Morges. Ces tendances devraient
                    encore se renforcer à cause de la raréfaction des terrains à
                    bâtir dans le Canton.
                  </p>
                  <p>
                    Depuis 2007, NewHome Services se positionne comme un
                    partenaire de confiance pour le rachat de votre maison ou
                    appartement ou votre développement immobilier. Son équipe
                    possède une expertise de pointe de la vente et de la
                    promotion dans les cantons de Vaud et Genève. Elle a réalisé
                    plus de 200 projets durant ses 15 années d’existence.
                  </p>
                  Si vous souhaitez obtenir des conseils personnalisés pour
                  valoriser votre propriété ou vendre votre bien, nous vous
                  invitons à nous contacter dès à présent. Nous vous proposerons
                  une approche sur-mesure qui correspond à vos besoins.
                </div>
                <div className="blog-columns2_right">
                  <figure className="pic">
                    <img
                      src={require('../assets/images/blog/blog7/blog7_3.png')}
                    />
                  </figure>
                </div>
              </div>
            </article>
          </div>
        </main>
        <section className="section-subscribe">
          <div className="container">
            <article className="section-subscribe_left">
              <p className="title">NEWSLETTER</p>
              Recevez des conseils et informations sur l'actualité de
              l'immobilier avec notre newsletter.
            </article>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="section-subscribe_form">
              <Input
                conClassName={'input'}
                errors={errors}
                name={'email'}
                label={'Email*'}
                ref={register(validation.email)}
              />
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-gradient">
                Je m’inscris
              </button>
            </form>
          </div>
        </section>

        <section className="section-conseil">
            <div className="container">
              <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
            </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default BlogArticle7;
