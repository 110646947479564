import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import IncludeScript from '../components/IncludeScript';
import ContactModal from '../components/ContactModal';

interface IServicesPageProps {

}

const ServicesPage = ({}: IServicesPageProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const scrool = (e: any) => {
    e.currentTarget.scrollIntoView({
      inline: 'center',
      block: 'center',
    });
  };

  return (
    <div className="page-bg">
      <IncludeScript name={'scroll'}/>
      <IncludeScript name={'tabs'}/>
    <div className="page">
      <Header/>
      <ContactModal isOpen={isOpen} setOpen={setIsOpen}/>

      <main className="services-page">
        <section className="services-advantages">
          <div className="container">
            <h2>Nos forces résident dans les domaines suivants</h2>
            <div className="scrollbar-outer">
            <ul className="tabs">
              <li onClick={scrool} className="tab active"><a href="#analysis">Analyse & Valorisation</a></li>
              <li onClick={scrool} className="tab"><a href="#partnership">Partenariat & Financement</a></li>
              <li onClick={scrool} className="tab"><a href="#development">Développement projet</a></li>
            </ul>
            </div>

            <div className="tabs-content">
              <div id="analysis" className="tabs-one">
                <section className="services-tab">
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <article className="services-advantages_one">
                        <div className="icon-number">
                          <span className="number">1</span>
                          <span className="icon"><img src={require('../assets/images/icon-target.svg')} /></span>
                        </div>
                        <p className="subtitle">Objectifs/Coûts et contraintes</p>
                        <div className="text">
                          <ul>
                            <li>Estimation de la valeur d’un bien immobilier.</li>
                            <li>Identification de son potentiel de développement.</li>
                            <li>Etablissement d’une estimation financière, esquisse volumétrique, estimation des coûts
                              de construction.
                            </li>
                            <li>Contrôle de la faisabilité en termes économique, technique et juridique.</li>
                          </ul>
                        </div>
                      </article>
                    </div>
                    <div className="col-12 col-md-4">
                      <article className="services-advantages_one line">
                        <div className="icon-number">
                          <span className="number">2</span>
                          <span className="icon"><img src={require('../assets/images/icon-diagram.svg')} /></span>
                        </div>
                        <p className="subtitle">Stratégie et mise en valeur</p>
                        <div className="text">
                          Détermination du potentiel de la parcelle plan directeur, coefficient d’occupation,
                          densification, optimisation des surfaces, et du marché, concurrence, clientèle cible, afin de
                          définir la typologie et le programme de construction.
                        </div>
                      </article>
                    </div>
                    <div className="col-12 col-md-4">
                      <article className="services-advantages_one line">
                        <div className="icon-number">
                          <span className="number">3</span>
                          <span className="icon"><img src={require('../assets/images/icon-finance.svg')} /></span>
                        </div>
                        <p className="subtitle">Analyse financière</p>
                        <div className="text">
                          <p>Analyse du marché, des prix de vente ou de location, compte de charge d’exploitation,
                            viabilité financière.</p>
                          Recommandations sur la stratégie à suivre (architectes, entreprises de construction,
                          planification, commercialisation, etc.).
                        </div>
                      </article>
                    </div>
                  </div>
                </section>
              </div>
              <div id="partnership" className="tabs-one">
                <section className="services-tab">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <article className="services-advantages_one flex line-left">
                        <div className="icon-number">
                          <span className="number">1</span>
                          <span className="icon"><img src={require('../assets/images/icon-handshake.svg')} /></span>
                        </div>
                        <div>
                          <p className="subtitle">Partenariat </p>
                          <div className="text">
                            Possibilité de développer un projet immobilier en étroite collaboration avec le propriétaire
                            du bien foncier lui permettant de conserver un ou plusieurs appartements ou de prendre une
                            participation au bénéfice de l’opération.
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="col-12 col-md-6">
                      <article className="services-advantages_one flex line">
                        <div className="icon-number">
                          <span className="number">2</span>
                          <span className="icon"><img src={require('../assets/images/icon-financement.svg')} /></span>
                        </div>
                        <div>
                          <p className="subtitle">Financement</p>
                          <div className="text">Préparation de plans financiers et de dossiers de demande de
                            financement. Pilotage avec les institutions concernées.
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </section>
              </div>
              <div id="development" className="tabs-one">
                <section className="services-tab">
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <article className="services-advantages_one">
                        <div className="icon-number">
                          <span className="number">1</span>
                          <span className="icon"><img src={require('../assets/images/icon-note.svg')} /></span>
                        </div>
                        <p className="subtitle">Avant-projet/Projet</p>
                        <div className="text">En collaboration avec l’architecte, élaboration d’un avant-projet, plans,
                          adaptation et optimisation, contrôle et validation des objectifs financiers avant le dépôt
                          d’un projet définitif pour une demande d’autorisation de construire.
                        </div>
                      </article>
                    </div>
                    <div className="col-12 col-md-4">
                      <article className="services-advantages_one line">
                        <div className="icon-number">
                          <span className="number">2</span>
                          <span className="icon"><img src={require('../assets/images/icon-touch.svg')} /></span>
                        </div>
                        <p className="subtitle">Pilotage du projet</p>
                        <div className="text">
                          <p>Gestion des relations et négociations avec les autorités, les voisins, les locataires et
                            tiers parties, les notaires, géomètres pour l’organisation du foncier, servitudes, création
                            de PPE.</p>
                          Planification de la construction : établissement d’un descriptif de travaux, d’un devis
                          général, appel d’offre et sélection d’entreprises pour la réalisation et préparation des
                          contrats d’entreprise.
                        </div>
                      </article>
                    </div>
                    <div className="col-12 col-md-4">
                      <article className="services-advantages_one line">
                        <div className="icon-number">
                          <span className="number">3</span>
                          <span className="icon"><img src={require('../assets/images/icon-shout.svg')} /></span>
                        </div>
                        <p className="subtitle">Commercialisation</p>
                        Mise en place d’un programme de vente, organisation du courtage ou de la mise en location. Plan
                        de communication, imagerie 3D, plaquette de vente, site internet dédié à la promotion
                      </article>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <a onClick={() => setIsOpen(true)} className="btn btn-gradient">Organisons un rendez-vous</a>
          </div>
        </section>
      </main>

      <section className="section-conseil">
          <div className="container">
            <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
          </div>
      </section>

      <Footer/>
    </div>
    </div>
  )
};

export default ServicesPage;
