import React, {useState} from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';

interface IBlogArticle1Props {

}


const BlogArticle3 = ({}: IBlogArticle1Props) => {
  const {handleSubmit, register, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  }

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service.subscribe(data.email)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (

  <div className="page-bg">
    <NewsLetterModal isOpen={isOpen} onClose={handleClose} defaultIsSuccess={isSuccess} />
  <div className="page page-about">
    <Header />
    <main className="blog-main">
        <div className="container blog-big_top">
          <p className="back-link"><Link to={'/blog'}><span>Retour au blog</span></Link></p>
            <article className="blog-big">
                <div className="blog-columns2 blog-big_first">
                    <div className="blog-columns2_left">
                        <h1>DÉVELOPPER UNE PROMOTION SUR VOTRE TERRAIN PEUT ÊTRE UN CASSE-TÊTE</h1>
                        <p>&nbsp;</p>
                    </div>
                    <div className="blog-columns2_right">
                        <figure className="pic"><img src={require('../assets/images/blog/blog3/blog3_big.png')} /></figure>
                    </div>
                </div>
                <div className="blog-columns2">
                    <div className="blog-columns2_left">
                        <h3>PROPRIÉTAIRES, FAUT-IL PRIVILÉGIER LE DÉVELOPPEMENT IMMOBILIER OU LA VENTE DE SON TERRAIN ?</h3>
                        <div className="main">Pour choisir s’il est plus avantageux de vendre ou développer sa parcelle, le propriétaire d’un terrain doit se poser les bonnes questions. En raison des nombreuses réglementations en vigueur, le processus de développement est à la fois long et complexe. Dans ce cas, il est vivement recommandé de s’attacher les services d’un spécialiste comme NewHome Services qui conseille les propriétaires souhaitant disposer de leur terrain et ce, depuis 2007.</div>
                        <p className="highlighted">Identifier le potentiel d’un terrain et bien comprendre les réglementations en vigueur</p>
                        <p>En Suisse, le droit immobilier est particulièrement complexe puisqu’il se répartit sur le niveau fédéral, cantonal et communal.</p>
                        Le propriétaire qui désire développer sa parcelle doit tout d’abord s’assurer du potentiel de son terrain. En effet, il devra se référer au zones d’affectation qui définissent la typologie des bâtiments pouvant y être construits. Ces règles d’urbanisme diffèrent souvent selon les cantons et les communes d’habitation. L’encadrement d’un professionnel est donc précieux, car il permet au propriétaire de bien comprendre les options qui s’offrent à lui afin d’optimiser son projet.
                    </div>
                    <div className="blog-columns2_right">
                        <figure className="pic"><img src={require('../assets/images/blog/blog3/blog3_2.png')} /></figure>
                    </div>
                </div>
            </article>
        </div>
        <div className="container">
            <article className="blog-big">
                <section className="blog-big_pic">
                    <figure className="pic-big"><img src={require('../assets/images/blog/blog3/blog3_3.png')} /></figure>
                    <div className="blog-columns_top">
                        <h3>Le développement immobilier, un processus<br />long et complexe</h3>
                    </div>
                    <div className="blog-columns">
                        <div className="blog-columns_left">
                            <p>Le développement immobilier est un processus long qui comporte de nombreux risques. Lorsque le potentiel d’un terrain est identifié, il faut définir un projet architectural de qualité, en adéquation avec la zone d’affectation, mais surtout anticiper les besoins du marché dans un horizon de 2 à 5 ans.<br />
                            Les autorisations de construire peuvent souffrir d’oppositions qui retardent la livraison de plusieurs mois, voire de quelques années.</p>
                            Une fois cette première étape validée, il faut s’assurer les services d’une entreprise de construction, qui respecte les coûts ainsi que les délais impartis, et qui livre des bâtiments conformes aux attentes. Sur ce point encore l’expertise d’un promoteur semble incontournable, car il connait les acteurs du marché et peut les choisir en connaissance de cause.
                        </div>
                        <div className="blog-columns_right">
                            <p>Viennent ensuite la mise sur le marché et la commercialisation des lots, qui nécessitent encore une fois les services de professionnels.</p>
                            <p>Enfin, les questions fiscales ne doivent pas être négligées. La taxation du propriétaire varie fortement selon sa situation, le projet et le planning de la vente.</p>
                            Toutes ces procédures nécessitent des compétences bien particulières et sont difficiles à entreprendre pour des particuliers. Les ressources financières et le temps nécessaire ne doivent pas être sousestimés. C’est pour cette raison que NewHome Services est à même de vous conseiller et vous accompagner dans vos réflexions et projets.
                        </div>
                    </div>
                </section>

                <div className="blog-columns">
                    <div className="blog-columns_left">
                        <p className="highlighted">NEWHOME SERVICES, UNE EXPERTISE DE PRÈS DE 15 ANS DANS LE DÉVELOPPEMENT IMMOBILIER</p>
                        Créée en 2007, la société NewHome Services possède une grande expérience dans la promotion immobilière. Elle maîtrise les différentes réglementations cantonales et communales pour avoir accompagné de nombreux propriétaires dans le développement et la commercialisation de leur terrain.
                    </div>
                    <div className="blog-columns2_right">
                        <figure className="pic"><img src={require('../assets/images/blog/blog3/blog3_4.png')} /> </figure>
                    </div>
                </div>
            </article>
        </div>
    </main>
    <section className="section-subscribe">
      <div className="container">
        <article className="section-subscribe_left">
          <p className="title">NEWSLETTER</p>
          Recevez des conseils et informations sur l'actualité de l'immobilier avec notre newsletter.
        </article>
        <form onSubmit={handleSubmit(onSubmit)} className="section-subscribe_form">
          <Input conClassName={'input'} errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
          <button disabled={isLoading} type="submit" className="btn btn-gradient">Je m’inscris</button>
        </form>
      </div>
    </section>

    <section className="section-conseil">
        <div className="container">
          <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
        </div>
    </section>

    <Footer />
  </div>
  </div>
  )
};

export default BlogArticle3;
