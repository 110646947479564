import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';


interface ISalePageProps extends RouteComponentProps{

}

const SalePage = ({location}: ISalePageProps) => {

  return (
    <div className="page-bg">
    <div className="page">
      <Header/>

      <main className="page-expertise">
        <div className="container">
            <h1>Notre Approche</h1>

            <article className="intro-text">
                <p className="subtitle">Nous croyons en une approche personnalisée pour chaque client.</p>
                Nous prenons le temps de comprendre vos besoins, vos préférences et vos contraintes afin de vous offrir des solutions qui correspondent à votre situation unique. Notre expertise, combinée à notre engagement envers votre satisfaction, garantit que vous prenez des décisions éclairées et rentables en toute discrétion.
            </article>
        </div>
      </main>

      <section className="services-advantages">
          <div className="container">
              <h2>Pourquoi choisir notre service de conseil immobilier ? </h2>
              <div className="row">
                  <div className="col-12 col-md-4">
                    <article className="services-advantages_one">
                      <div className="icon-number">
                        <span className="number">1</span>
                        <span className="icon"><img src={require('../assets/images/icon-personal.svg')} /></span>
                      </div>
                      <p className="subtitle">Personnalisation:</p>
                      <div className="text">
                          Nous adaptons nos services en fonction de vos besoins spécifiques.
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-4">
                    <article className="services-advantages_one line">
                      <div className="icon-number">
                        <span className="number">2</span>
                        <span className="icon"><img src={require('../assets/images/icon-support.svg')} /></span>
                      </div>
                      <p className="subtitle">Accompagnement complet:</p>
                      <div className="text">
                        De la réflexion initiale à la concrétisation de votre projet, nous sommes là pour vous guider à chaque étape du processus en garantissant la confidentialité.
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-4">
                    <article className="services-advantages_one line">
                      <div className="icon-number">
                        <span className="number">3</span>
                        <span className="icon"><img src={require('../assets/images/icon-result.svg')} /></span>
                      </div>
                      <p className="subtitle">Résultats:</p>
                      <div className="text">
                          Notre objectif ultime est votre satisfaction et la réalisation optimale de vos objectifs.
                      </div>
                    </article>
                  </div>
              </div>
          </div>
      </section>

      <section className="page-expertise_services">
          <div className="container">
              <article className="page-expertise_services-one service1">
                <span className="icon"><img src={require('../assets/images/services/icon1.svg')} /></span>
                <div className="content">
                    <p className="title">Estimation</p>
                    <div className="text">Notre service d'estimation immobilière propose une <span className="bold">évaluation</span> à jour et précise de votre propriété. Nous utilisons des bases de données statistiques de vente et notre <span className="bold">connaissance approfondie</span> du marché pour assurer l'exactitude de nos évaluations. En outre, un <span className="bold">comité d'experts</span> évalue chaque bien selon ses caractéristiques uniques pour fixer un <span className="bold">prix équitable</span>. Avec ces informations, nous pouvons vous offrir une <span className="bold">estimation</span> qui correspond aux tendances récentes du marché.</div>
                </div>
              </article>
              <article className="page-expertise_services-one service2">
                <span className="icon"><img src={require('../assets/images/services/icon2.svg')} /></span>
                <div className="content">
                    <p className="title">Expertise</p>
                    <div className="text">L'expertise immobilière gagne en <span className="bold">précision</span> grâce à l'intervention d'un <span className="bold">expert qualifié et agréé</span>, rendant son rapport valide sur le plan juridique. Celui-ci examine en détail tous les aspects de la propriété, résumés dans un <span className="bold">rapport d'expertise</span>. Ce document est essentiel pour le partage des biens lors de divorces ou séparations, ainsi que pour les <span className="bold">successions</span> et donations.</div>
                </div>
              </article>
              <article className="page-expertise_services-one service3">
                <span className="icon"><img src={require('../assets/images/services/icon3.svg')} /></span>
                <div className="content">
                    <p className="title">Analyse du potentiel</p>
                    <div className="text">Fort de notre expérience étendue dans la conduite de projets immobiliers, nous détectons les possibilités de <span className="bold">développement</span> ou d'<span className="bold">amélioration</span> pour maximiser la valeur de votre bien. Notre analyse explore toutes les <span className="bold">stratégies possibles</span>, incluant la démolition et reconstruction, l'extension, la surélévation, la <span className="bold">transformation</span> et le morcellement, entre autres. Nous vous proposons une <span className="bold">étude détaillée</span> et spécifique pour chaque solution envisagée.</div>
                </div>
              </article>
              <article className="page-expertise_services-one service4">
                <span className="icon"><img src={require('../assets/images/services/icon4.svg')} /></span>
                <div className="content">
                    <p className="title">Conseil</p>
                    <div className="text">Bénéficiez de <span className="bold">conseils immobiliers personnalisés</span>, fondés sur une analyse précise du marché, afin de diriger vos décisions immobilières de manière stratégique. Nos <span className="bold">recommandations</span> couvrent la vente, la location, le développement de projets, ainsi que la <span className="bold">gestion des travaux de rénovation</span>. Nous intégrons aussi les dimensions <span className="bold">fiscales et financières</span> pour une gestion globale et optimale de vos affaires immobilières.</div>
                </div>
              </article>
              <article className="page-expertise_services-one service5">
                <span className="icon"><img src={require('../assets/images/services/icon5.svg')} /></span>
                <div className="content">
                    <p className="title">Vente</p>
                    <div className="text">Notre équipe accompagne chaque phase de la <span className="bold">vente</span> de votre appartement ou maison, assurant une expérience sans accroc, de l'estimation initiale jusqu'à la signature chez le <span className="bold">notaire</span>. Notre intégrité et notre connaissance du marché <span className="bold">protègent vos intérêts</span> de façon optimale. Nous offrons un <span className="bold">service intégral</span> incluant l'élaboration de plans, la création de brochures, la publication d'annonces, la prise de photos professionnelles, l'<span className="bold">organisation de visites</span> et la préparation des documents nécessaires. Avec nous, la vente de votre propriété se déroulera en toute <span className="bold">confiance</span>.</div>
                </div>
              </article>
              <article className="page-expertise_services-one service6">
                <span className="icon"><img src={require('../assets/images/services/icon6.svg')} /></span>
                <div className="content">
                    <p className="title">Rénovation</p>
                    <div className="text">Valorisez votre bien immobilier avec nos conseils en <span className="bold">rénovation</span>. Notre expertise vous aidera à en <span className="bold">accroître la valeur et l'attrait</span>. Nous proposons diverses options de rénovation, incluant l'<span className="bold">optimisation</span> des espaces et l'agencement des pièces, accompagnées d'estimations de coûts pour répondre à vos attentes et à votre budget. Nous vous soutenons dans tous les aspects de la <span className="bold">réalisation</span> et du <span className="bold">financement</span> des travaux.</div>
                </div>
              </article>
              <article className="page-expertise_services-one service7">
                <span className="icon"><img src={require('../assets/images/services/icon7.svg')} /></span>
                <div className="content">
                    <p className="title">Développement</p>
                    <div className="text">Depuis 2007, NewHome Services se distingue dans le domaine du <span className="bold">développement</span> et de la <span className="bold">réalisation de projets immobiliers</span>. Notre expertise s'étend à la <span className="bold">construction</span> d'immeubles locatifs, de propriétés par étages (PPE), de villas, et à la transformation ainsi qu'à la <span className="bold">restructuration de bâtiments existants</span>. Nous gérons vos projets du début à la fin, en travaillant en étroite collaboration avec vous pour élaborer un projet aligné sur vos objectifs.</div>
                    <p className="link"><Link to={'/services'}><span>See More</span></Link></p>
                </div>
              </article>
          </div>
      </section>

      <Footer/>
    </div>
    </div>
  )
};

export default SalePage;
