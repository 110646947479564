import React from 'react';
import Modal from 'react-responsive-modal';

const UserModal = ({isOpen, setSelectedUser, selectedUser}) => {
  return(
        <Modal
          key={selectedUser.id}
          open={isOpen}
          onClose={() => setSelectedUser(null)}
          showCloseIcon={false}>
          <div className={'iziModal isAttached hasScroll'}>
            <div className={'iziModal-wrap'}>
              <div className={'iziModal-content'}>
            <div className="modal-body modal-body_user">
              <button onClick={() => setSelectedUser(null)} className="modal-close">&nbsp;</button>

              <div className="modal-content">
                  <div key={selectedUser.id} className="modal-user">
                      <figure className="modal-user_pic">
                        <img
                          src={selectedUser.imageUrl}
                          alt={selectedUser.name}
                        />
                      </figure>
                      <article className="modal-user_content">

                        <p className="modal-user_name">{selectedUser.name}</p>
                        <p className="modal-user_job">{selectedUser.job}</p>
                        <a onClick={(e) => e.stopPropagation()}
                          href={selectedUser.linkedin}
                          target="_blank">
                            {selectedUser.linkedin}
                        </a>
                        <div className="modal-user_desc">{selectedUser.description}</div>
                        <div className="modal-user_lang">
                            <p>LANGUES</p>
                            {selectedUser.language}
                        </div>
                      </article>
                  </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </Modal>
  )
};

export default UserModal;
