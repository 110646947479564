import React, {useEffect, useState} from 'react';
import Modal from 'react-responsive-modal';
import Input from './Input';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import {generateClassName} from '../utils/generateClassName';

interface IContactModalProps {
  isOpen: boolean;
  setOpen: any;
}

const options = [
  "Publicité",
  "Article dans la presse",
  "Bouche à oreille",
  "Emailing",
];

const ContactModal = ({isOpen, setOpen}: IContactModalProps) => {
  const {handleSubmit, register, watch, errors, reset, setValue} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocus, setIsFocus] = useState(false);


  const onSubmit = (data: any) => {
    setIsLoading(true);
    service.contactForm(data)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
        }
      })
      .finally(() => {
        (window as any).dataLayer.push({
            event: "generate_lead",
            data,
            eventLabel: "Contact form"
        });
        setIsLoading(false);
      })
  };

  useEffect(() => {
    setIsSuccess(false);
  }, [isOpen]);

  const onClick = (str: string) => {
    setValue('marketing', str);
  };

  return(
    <Modal
      open={isOpen}
      onClose={() => setOpen(false)}
      showCloseIcon={false}>
      <div className={'iziModal isAttached hasScroll'}>
        <div className={'iziModal-wrap'}>
          <div className={'iziModal-content'}>
        <div className="modal-body" style={{
          height: isSuccess ? '100vh' : 'initial'
        }}>
          <button onClick={() => setOpen(false)} className="modal-close">&nbsp;</button>

          <div className="modal-content">
            <figure className="modal-pic"><img src={require('../assets/images/pic-contact.png')} /></figure>
            <div className="modal-form">
                <p className="modal-title">CONTACTEZ-NOUS</p>
                {
                  !isSuccess ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Input errors={errors} name={'firstName'} label={'Nom*' } ref={register(validation.required)} />
                      <Input errors={errors} name={'lastName'} label={'Prénom*' } ref={register(validation.required)} />
                      <Input errors={errors} name={'phone'} label={'Numéro de téléphone*' } ref={register(validation.required)} />
                      <Input errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
                      <div className="form-item">
                        <label>Message</label>
                        <textarea ref={register} name={'message'}></textarea>
                      </div>
                      <div className="dropdown">
                        <Input
                          conClassName={'form-item_right'}
                          autoComplete={'new-password'}
                          errors={errors}
                          onFocus={() => setIsFocus(true)}
                          onBlur={() => {
                            setIsFocus(false)
                          }}
                          name={'marketing'}
                          label={'Comment avez-vous entendu parlé de NewHome Services' }
                          ref={register(validation.required)}
                        />
                        <div className={generateClassName('dropdown-list', {
                          'dropdown-list-focus': isFocus
                        })} style={{
                          marginTop: '-2.8rem'
                        }
                        }>
                          <ul>
                            {
                              options.map((o, i) => {
                                return (
                                  <li key={i} onClick={() => onClick(o)}>{o}</li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="form-item_checkbox">
                        <input type="checkbox" id="accept2" name={'check'} ref={register} />
                        <label htmlFor="accept2">J’ai lu et j’accepte la <a target={'_blank'} href="https://wild-dots.com/fr/mentions-legales/">politique de confidentialité</a> de ce site*</label>
                      </div>
                      <button disabled={!watch('check') || isLoading} type="submit" className="btn btn-gradient">Envoyer</button>
                    </form>
                  ) : (
                    <div className="estimate-end" style={{
                      marginTop: '15rem'
                    }
                    }>
                      Votre message a bien été envoyé.
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </Modal>
  )
};

export default ContactModal;
