import React from 'react';
import {RouteComponentProps, Link} from 'react-router-dom';
import IncludeScript from '../components/IncludeScript';
import Footer from '../components/Footer';
import Header from '../components/Header';


interface ISalePageProps extends RouteComponentProps{

}

const SalePage = ({location}: ISalePageProps) => {

  return (
    <div className="page-bg">
    <div className="page">
     <Header/>

      <main className="estimate-page">
        <IncludeScript name={'loader'}/>

        {/* For sale */}
        <div className="ImmomigContainer">
            <iframe className="Immomig" src="" data-src="https://immobilier.newhomeservices.ch/fr/objects-sale" data-hostname="https://immobilier.newhomeservices.ch" width="100%" height="800" scrolling="no"></iframe>
        </div>

        {/* Rent */}
        <div className="ImmomigContainer">
            <iframe className="Immomig" src="" data-src="https://immobilier.newhomeservices.ch/fr/objects-rent" data-hostname="https://immobilier.newhomeservices.ch" width="100%" height="800" scrolling="no"></iframe>
        </div>

        {/* Login */}
        <div className="ImmomigContainer">
            <iframe className="Immomig" src="" data-src="https://immobilier.newhomeservices.ch/fr/login" data-hostname="https://immobilier.newhomeservices.ch" width="100%" height="800" scrolling="no"></iframe>
        </div>
      </main>

      <section className="section-conseil">
          <div className="container">
            <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
          </div>
      </section>

      <Footer/>
    </div>
    </div>
  )
};

export default SalePage;
