import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import IncludeScript from '../components/IncludeScript';
import propeties from '../mockData/propeties';

interface IPortfolioItemPageProps extends RouteComponentProps<any> {

}

const PortfolioItemPage = ({match}: IPortfolioItemPageProps) => {
  const {id, index} = match.params;

  const findItem = propeties.find(p => p.id == id)!;
  const property: any = findItem.properties[index];

  return (
    <div className="page-bg">
    <div className="page page-portfolio">
      <Header/>
      <IncludeScript name={'owlCarousel'}/>
      <main className="portfolio-page_one">
        <div className="container">
          <p className="back-link"><Link to={'/portfolio/' + id}><span>Retour au portfolio</span></Link></p>
          <h1>{property.title}</h1>
          {property.description}
          {property.href && property.link &&
            <p><a href={property.href} className="info-link" target={'_blank'}>{property.link}</a></p>
          }

          <div id="sync1" className="owl-carousel owl-theme portfolio-slider-big">
            {
              property.images.map((img, i) => {
                return (
                  <div key={i} className="item">
                    <figure className="portfolio-big"><img src={img} /></figure>
                  </div>
                )
              })
            }
          </div>

          <div id="sync2" className="owl-carousel owl-theme portfolio-slider-thumb">
            {
              property.images.map((img, i) => {
                return (
                  <div key={i} className="item">
                    <figure className="carousel-pic"><img src={img} /></figure>
                  </div>
                )
              })
            }
          </div>
        </div>
      </main>

      <section className="section-conseil">
          <div className="container">
            <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
          </div>
      </section>

      <Footer/>
    </div>
    </div>
  )
};

export default PortfolioItemPage;
