import React, {useState} from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';

interface IBlogArticle1Props {

}

const BlogArticle1 = ({}: IBlogArticle1Props) => {
  const {handleSubmit, register, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  }

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service.subscribe(data.email)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (

    <div className="page-bg">
      <NewsLetterModal isOpen={isOpen} onClose={handleClose} defaultIsSuccess={isSuccess} />
    <div className="page page-about">
      <Header />

      <main className="blog-main">
        <div className="container blog-big_top">
          <p className="back-link"><Link to={'/blog'}><span>Retour au blog</span></Link></p>

          <article className="blog-big">
            <div className="blog-columns2 blog-big_first">
              <div className="blog-columns2_left">
                <h1>Pertes de chaleur dues au manque D’ISOLATION</h1>
              </div>
              <div className="blog-columns2_right">
                <figure className="pic"><img src={require('../assets/images/blog/blog1_big.png')} /></figure>
              </div>
            </div>
            <div className="blog-columns2">
              <div className="blog-columns2_left">
                <h3>LES AVANTAGES DE LA RÉNOVATION ÉCOLOGIQUE</h3>
                <div className="main">L’écologie prend une place de plus en plus importante dans le domaine de
                  l’immobilier. De nombreuses mesures peuvent être mises en oeuvre pour améliorer l’efficience
                  énergétique d’un bâtiment. Au-delà des préoccupations environnementales, ces changements ont plusieurs
                  impacts positifs pour les propriétaires : ils permettent de réaliser d’importantes économies
                  financières, de vivre dans un environnement sain et même d’améliorer la durabilité du bâti. Dans
                  certains cas, la rénovation devient malheureusement trop coûteuse, il est alors plus avantageux
                  d’acheter du neuf.
                </div>
                <p className="highlighted">Le développement durable est devenu un critère essentiel lors la construction
                  de bâtiments ou de la rénovation de biens immobiliers existants.</p>
                Des labels certifient les performances énergétiques d’une villa ou d’un immeuble et les instances
                communales, cantonales ou fédérales accordent des subventions aux particuliers qui décident de rénover
                leur bien.
              </div>
              <div className="blog-columns2_right">
                <figure className="pic"><img src={require('../assets/images/blog/blog1_2.png')} /></figure>
              </div>
            </div>
          </article>
        </div>
        <div className="container">
          <article className="blog-big">
            <section className="blog-big_pic">
              <figure className="pic-big"><img src={require('../assets/images/blog/blog1_3.png')} /></figure>
              <div className="blog-columns_top">
                <h3>CONSEIL</h3>
                <p className="highlighted">Quelles solutions écologiques s’offrent à vous ?</p>
              </div>
              <div className="blog-columns">
                <div className="blog-columns_left">
                  Un assainissement énergétique nécessite d’abord une isolation thermique efficace puisque 70% de la
                  consommation d’une villa sert à chauffer les pièces de vie. La perte de chaleur peut être évitée grâce
                  à des matériaux d’isolation efficients et si possible écologiques, tels que la fibre de bois, la laine
                  de mouton, le lin, le chanvre, la cellulose et le liège. L’installation de double ou triple vitrage
                  empêche par ailleurs les déperditions d’énergie au niveau des fenêtres.
                </div>
                <div className="blog-columns_right">
                  Lorsqu’une villa est bien isolée, se pose alors la question de l’approvisionnement en énergie. Les
                  sources à privilégier sont le solaire, les pompes à chaleur ou le raccordement à un réseau de
                  chauffage à distance. À titre indicatif, rappelons que les panneaux photovoltaïques peuvent fournir
                  jusqu’à 70% des besoins annuels d’un foyer en eau chaude. Quant aux pompes à chaleur, elles ne
                  nécessitent qu’un tiers à un quart de l’énergie requise par un chauffage électrique, tout en
                  produisant quatre fois plus de chaleur utile que la quantité d’énergie électrique consommée.
                </div>
              </div>
            </section>

            <div className="blog-columns">
              <div className="blog-columns_left">
                <h3>QUELLES CERTIFICATIONS ET QUELLES SUBVENTIONS POUR L’ENGAGEMENT ÉCOLOGIQUE ?</h3>
                <p>Deux standards généraux se distinguent en matière d’impact environnemental pour un bien immobilier :
                  Hautes Performances Énergétiques (HPE) et Très Hautes Performances Énergétiques (THPE).</p>
                <p className="highlighted">Si votre propriété répond à ces critères, alors il est possible de bénéficier
                  d’une exonération de l’impôt immobilier complémentaire (IIC) pour une durée de 20 ans.</p>
                <p>Un assainissement énergétique permet également de bénéficier de subventions communales, cantonales ou
                  même fédérales. Mais chaque cas est unique et le meilleur moyen pour identifier des possibles soutiens
                  est de commander un audit de votre situation auprès d’un architecte ou d’un ingénieur.</p>
              </div>
              <div className="blog-columns_right">
                <h3>ENVISAGER UN ACHAT NEUF OU UNE RECONSTRUCTION PLUTÔT QU’UNE RÉNOVATION</h3>
                <p>Il arrive malheureusement souvent qu’une rénovation engendre des coûts trop importants par rapport
                  aux bénéfices obtenus, notamment dans le cas de biens immobiliers vieillissants. Il devient alors plu
                  intéressant d’acheter du neuf ou de reconstruire sur la parcelle concernée. Le propriétaire bénéficie
                  ainsi des dernières avancées en matière d’efficience énergétique et peut choisir un logement qui
                  répond parfaitement à ses attentes écologiques, le tout sans devoir subir les désagréments liés aux
                  travaux et aménagements du bien immobilier dans lequel il vit. NewHome Services est spécialiste dans
                  le rachat de biens immobiliers sur l’arc lémanique. Elle permet aux propriétaires de réaliser des
                  opérations financières attractives afin de se reloger dans un habitat neuf et donc plus
                  écologique.</p>

                En savoir plus :<br/><a href="https://www.leprogrammebatiments.ch/fr/"
                                       target="_blank">https://www.leprogrammebatiments.ch/fr/</a>
              </div>
            </div>
          </article>
        </div>
      </main>

      <section className="section-subscribe">
        <div className="container">
          <article className="section-subscribe_left">
            <p className="title">NEWSLETTER</p>
            Recevez des conseils et informations sur l'actualité de l'immobilier avec notre newsletter.
          </article>
          <form onSubmit={handleSubmit(onSubmit)} className="section-subscribe_form">
            <Input conClassName={'input'} errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
            <button disabled={isLoading} type="submit" className="btn btn-gradient">Je m’inscris</button>
          </form>
        </div>
      </section>

      <section className="section-conseil">
          <div className="container">
            <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
          </div>
      </section>

     <Footer />
    </div>
    </div>
  )
};

export default BlogArticle1;
