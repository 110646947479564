import React from 'react';
import {generateClassName} from '../utils/generateClassName';
import MaskedInput from 'react-input-mask';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  errors?: any;
  className?: string;
  conClassName?: string;
}

const Input = React.forwardRef(({name, label, type, errors, className = '', conClassName = '', ...rest}: IInputProps, ref: any) => {
  const error = errors && errors[name] ? errors[name].message : '';

  return (
    <div className={conClassName}>
      <div className={generateClassName(`form-item ${className}`, {
        error: !!error
      })}>
        <label>
          {label}
        </label>
        {
          name === 'phone' ? (
            <MaskedInput
              mask={'99 99 999 99 99'}
              name={name}
              inputRef={r => {
                ref(r);
              }}
              maskChar={' '}
              inputMode={'tel'}
              {...rest}
            />
          ) : (
            <input type={type} name={name} ref={ref} {...rest} />
          )
        }
        {error && <p className="error-text">{error}</p>}
      </div>
    </div>
  )
});


export default Input;
