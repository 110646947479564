import React, {useState} from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import {useForm} from 'react-hook-form';
import useValidation from '../useHooks/useValidation';
import service from '../services/service';
import Input from '../components/Input';
import NewsLetterModal from '../components/NewsLetterModal';

interface IBlogArticle1Props {

}

const BlogArticle4 = ({}: IBlogArticle1Props) => {
  const {handleSubmit, register, errors, reset} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const validation = useValidation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
  }

  const onSubmit = (data: any) => {
    setIsLoading(true);
    service.subscribe(data.email)
      .then(res => {
        if(res.success) {
          reset();
          setIsSuccess(true);
          setIsOpen(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (

    <div className="page-bg">
      <NewsLetterModal isOpen={isOpen} onClose={handleClose} defaultIsSuccess={isSuccess} />
    <div className="page page-about">
      <Header />

      <main className="blog-main">
        <div className="container blog-big_top">
            <p className="back-link"><Link to={'/blog'}><span>Retour au blog</span></Link></p>

            <article className="blog-big">
                <div className="blog-columns2 blog-big_first">
                    <div className="blog-columns2_left">
                        <h1>ÉVALUER LE POTENTIEL DE DÉVELOPPEMENT DE SA PARCELLE</h1>
                        <p>&nbsp;</p>
                    </div>
                    <div className="blog-columns2_right">
                        <figure className="pic"><img src={require("../assets/images/blog/blog4/blog4_big.png")}/></figure>
                    </div>
                </div>
                <div className="blog-columns2">
                    <div className="blog-columns2_left">
                        <div className="main">Lorsqu’on est propriétaire d’une parcelle et que l’on souhaite la mettre en vente, il est essentiel d’évaluer sa valeur au plus près de la réalité. Les paramètres à prendre en compte sont nombreux, que cela soit d’un point de vue des réglementations en vigueur, de la typologie du terrain ou de sa qualité intrinsèque. Retour sur les points importants à considérer lors d’une estimation</div>
                        <h3>QUE FAUT-IL PRENDRE EN COMPTE LORS D’UNE ESTIMATION ?</h3>
                        <p className="highlighted">Lois et réglementations</p>
                        Les parcelles sont soumises à de multiples lois et réglementations, qu’elles soient fédérales, cantonales ou communales. Dans le canton de Vaud, ce sont les communes qui dictent la plupart des règles en vigueur, alors que la prérogative est cantonale à Genève. Au niveau fédéral, la Loi sur l’Aménagement du Territoire (LAT) définit les grandes lignes à suivre en matière de construction. Elle entre parfois en contradiction avec des réglementations plus locales, par exemple des Plans Généraux d’Affectation Communaux (PGA). Cet aspect complexe joue un rôle primordial sur l’estimation du prix de vente d’un terrain et il est nécessaire de faire appel à un expert dans le domaine pour en saisir tous les tenants et aboutissants.
                    </div>
                    <div className="blog-columns2_right">
                        <figure className="pic"><img src={require("../assets/images/blog/blog4/blog4_2.png")}/></figure>
                    </div>
                </div>
            </article>
        </div>
        <div className="container">
            <article className="blog-big">
                <section className="blog-big_pic">
                    <figure className="pic-big"><img src={require("../assets/images/blog/blog4/blog4_3.png")}/></figure>
                    <div className="blog-columns">
                        <div className="blog-columns_left">
                            <p className="highlighted">Contraintes et restrictions</p>
                            <p>Les servitudes (restrictions de droit privé) ont aussi un impact sur le prix de vente d’une parcelle. Elles recouvrent notamment les constructions (autorisation de bâtir, empiètement, superficie, etc.), mais également les droits de passage, les plantations et clôtures, ou encore les canalisations. Il est essentiel de lister l’ensemble de ces points lors d’une estimation.</p>
                            <p>La typologie d’un terrain, par exemple la présence de forêts, de cours d’eau ou de végétation, peut créer des limitations de la surface sur laquelle il est possible de construire. L’abattage d’arbres est notamment soumis à des réglementations très strictes. L’absence de chemin d’accès ou l’impossibilité de l’agrandir réduit aussi ce qu’il est possible d’ériger sur le site.</p>
                            La présence de bâtiments existants peut avoir des implications variables  : une ferme ancienne protégée, donc impossible à détruire et aura un effet négatif sur le prix d’une parcelle. Deux normes entrent aussi en jeu lorsque l’on veut savoir si un site construit est protégé : l’ISOS (Inventaire fédéral des sites construits d’importance nationale à protéger en Suisse) établi par l’Office Fédéral de la Culture, ainsi que l’inventaire tenu par l’ICOMOS (Conseil international des monuments et des sites). Ces deux institutions évaluent la valeur patrimoniale du bâti et déterminent par la même occasion ce qu’il est possible de détruire ou de transformer, le tout avec pour objectif d’assurer la qualité des espaces de vie.
                        </div>
                        <div className="blog-columns_right">
                            <p className="highlighted">Caractéristiques de la parcelle</p>
                            Lors de l’estimation, aucun facteur ne doit être négligé. La surface de la parcelle est évidemment un aspect à prendre en compte, mais sa forme est également très importante : une parcelle avec une configuration atypique, par exemple étirée sur la longueur, sera beaucoup plus difficilement constructible qu’une parcelle large et rectangulaire.<br/>
                            L’inclinaison du terrain peut aussi devenir un désavantage : une pente trop raide renchérit la construction et diminue la valeur. La qualité du sol et la nécessité éventuelle de le dépolluer ont aussi un impact à ce niveau.<br/>
                            Enfin la localisation d’un terrain joue évidemment un rôle fondamental : une vue lac augmentera considérablement la valeur d’une parcelle, alors que la proximité d’une autoroute ou d’une voie de circulation passante aura l’effet contraire.
                        </div>
                    </div>
                </section>

                <div className="blog-columns">
                    <div className="blog-columns_left">
                        <p className="highlighted">NEWHOME SERVICES, VOTRE PARTENAIRE PRIVILÉGIÉ DANS L’ESTIMATION DE VOTRE TERRAIN</p>
                        Créée en 2007, la société NewHome Services possède une large expertise de la promotion immobilière dans les cantons de Vaud et Genève. Spécialiste dans le rachat de parcelle, elle saura vous accompagner dans l’estimation de votre terrain pour en obtenir un prix équitable qui correspond au marché actuel.
                    </div>
                    <div className="blog-columns2_right">
                        <figure className="pic"><img src={require("../assets/images/blog/blog4/blog4_4.png")}/></figure>
                    </div>
                </div>
            </article>
        </div>
    </main>

    <section className="section-subscribe">
        <div className="container">
            <article className="section-subscribe_left">
                <p className="title">NEWSLETTER</p>
                Recevez des conseils et informations sur l'actualité de l'immobilier avec notre newsletter.
            </article>
            <form onSubmit={handleSubmit(onSubmit)} className="section-subscribe_form">
            <Input conClassName={'input'} errors={errors} name={'email'} label={'Email*' } ref={register(validation.email)} />
                <button disabled={isLoading} type="submit" className="btn btn-gradient">Je m’inscris</button>
            </form>
        </div>
    </section>

    <section className="section-conseil">
        <div className="container">
          <Link to={'/conseil'}><figure className="section-conseil_pic"><img src={require('../assets/images/expertise.svg')} /></figure></Link>
        </div>
    </section>

     <Footer />
    </div>
    </div>
  )
};

export default BlogArticle4;
