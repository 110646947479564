import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ContactModal from './ContactModal';

interface IFooterProps {

}

const Footer = ({}: IFooterProps) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <footer className="footer">
       <ContactModal isOpen={isOpen} setOpen={setIsOpen}/>
       <span className="footer-corner">
            <a target={'_blank'} href="https://www.linkedin.com/company/28494114/" className="linkedin"><img src={require('../assets/images/linkedin.svg')} /></a>
        </span>
      <div className="container">
        <div className="footer-logos">
          <Link to={'/'} className="footer-logo"><img src={require('../assets/images/logo_w.png')} alt="New Home (logo)" /></Link>
          <a href="" className="footer-addiv"><img src={require('../assets/images/logo_addiv.png')} alt="" /></a>
        </div>
        <address className="footer-address">
          <p>NewHome Services SA</p>
          Avenue Perdtemps 23<br/>
          1260 Nyon – 1
        </address>
        <ul className="footer-menu">
          <li><Link to={'/'}>Accueil</Link></li>
          <li><Link to={'/conseil'}>Conseil</Link></li>
          <li><span onClick={()=>{
                 window.location.href="/vendre"
              }} >A Vendre</span></li>
          <li><Link to={'/services'}>Développement</Link></li>
        </ul>
        <ul className="footer-menu">
          <li><Link to={'/portfolio'}>Portfolio</Link></li>
          <li><Link to={'/about'}>équipe</Link></li>
          <li><Link to={'/blog'}>Blog</Link></li>
        </ul>

        <div className="footer-contact">
          <p className="phone">tél: <a href="tel:+41223629610" onClick={() => {(window as any).dataLayer.push({event: 'phoneClick'})}}>+41 22 362 96 10</a></p>
          <p className="phone"><a href="/declaration">Déclaration</a></p>
          <p className="footer-contact"><a
            onClick={() => {
                setIsOpen(true);
                (window as any).dataLayer.push({event: 'contactClick'});
            }
          } className="btn btn-blue btn-left"><span>Contact</span></a></p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
